var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({attrs:{"id":"app-table","hide-default-footer":"","disable-pagination":"","disable-filtering":"","mobile-breakpoint":0,"disable-sort":_vm.smDown || _vm.disableSort,"headers":_vm.headings,"items":_vm.items,"loading":_vm.loading,"header-props":_vm.headerProps,"items-per-page":-1},on:{"update:sort-desc":_vm.isSortedDesc},scopedSlots:_vm._u([{key:"header.actions",fn:function(){return [_c('span')]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
var index = ref.index;
return [_c('tr',{key:index,staticClass:"tr",class:[
        ("tr-" + index),
        index % 2 === 0 ? 'tr-even' : 'tr-odd',
        _vm.clickableRow ? 'xcursor-pointer' : '' ],on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click:row', item)}}},_vm._l((headers),function(prop,tdIndex){return _c('td',{key:("td-" + index + "-" + tdIndex),staticClass:"td",class:[("td-" + (prop.value)), prop.tdClass]},[_vm._t(prop.value,function(){return [_vm._v(" "+_vm._s(item[prop.value])+" ")]},{"item":item,"index":index})],2)}),0)]}},{key:"progress",fn:function(){return [_c('v-progress-linear',{staticClass:"my-1",attrs:{"indeterminate":"","height":10,"color":"primary"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"xmin-h-[300px] xflex xflex-col xjustify-center xitems-center",class:_vm.emptyClass},[_vm._t("no-data",function(){return [_c('div',{staticClass:"xflex xflex-col xjustify-center xitems-center"},[_c('v-avatar',[_c('v-img',{attrs:{"contain":"","src":_vm.logoSquare}})],1),_c('div',{staticClass:"title primary--text",domProps:{"innerHTML":_vm._s(_vm.emptyText)}})],1)]})],2)]},proxy:true}],null,true)},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }