<template>
  <v-card
    color="transparent"
    min-height="100vh"
    flat
    :class="[tabletDown ? '' : 'xp-[1em]']"
  >
    <v-card-title class="xflex xflex-col">
      <h1 class="xfont-[600] xw-full xtext-[24px] xleading-[29px]">
        My Records
      </h1>
    </v-card-title>
    <app-tabs :tabs="tabs" optional>
      <router-view :key="$route.fullPath"></router-view>
    </app-tabs>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  computed: {
    isMyRecord() {
      return this.$route.name === "app-my-record";
    },
    isMyPTOs() {
      return this.$route.name === "app-my-ptos";
    },
    tabs() {
      return [
        {
          to: {
            name: "app-my-record",
            params: { year_month: moment().format("YYYY-MM") },
          },
          name: "Records",
        },
        // {
        //   to: { name: "app-my-pending-edits" },
        //   name: "Pending Edits",
        // },
        // {
        //   to: { name: "app-my-ptos" },
        //   name: "PTO's",
        // },
      ];
    },
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "app-my-record",
        params: { year_month: moment().format("YYYY-MM") },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
