<template>
  <div
    :class="{
      'xgrid-cols-1 !xbg-none': smDown,
      'xgrid-cols-2 xbg-contain xcontainer ': !smDown,
      'xbg-[length:50%_100%] !xb-[2em] !xmax-w-[90%]': tablet,
    }"
    class="xgrid xmx-auto xpx-2 xbg-[#F3F3F3] xh-[100vh]xbg-no-repeat xbg-right-bottom"
    id="home"
    :style="mdUp ? { backgroundImage: `url(${homeFront})` } : {}"
  >
    <div
      class="md:xorder-1 xorder-2 xcol-span-1 lg:xp-[4em] md:xp-[3em] xpb-[2em] xflex xflex-col xjustify-center xitems-stretch"
    >
      <div class="xflex xflex-col xjustify-center xgap-[0.6em] xitems-stretch">
        <div
          :class="{ 'xtext-center': smDown }"
          class="xfont-extrabold xpt-[1em] lg:xtext-[72px] md:xtext-[60px] xtext-[40px] xleading-[1.2em] xtext-[#000000]"
        >
          Time tracking made easy by Mesasix
        </div>
        <p
          :class="{ 'xtext-center xp-[1em]': smDown }"
          class="xfont-[400] xtext-[22px] xleading-[35px] xtext-[#1F1F1F]"
        >
          Mesasix Time Tracker is a customizable smart time tracking tool
          integrated within Slack made to increase your productivity and
          effectivity at work.
        </p>
        <v-btn
          color="primary"
          :class="{ 'xm-auto': smDown }"
          class="text-none xw-[300px] xmy-[0.5em]"
          x-large
          @click="$router.push({ name: 'install-app' })"
        >
          Try Free Trial - {{ app_trial_days || 7 }} days!
        </v-btn>
      </div>
      <div
        :class="{ 'xmb-[1em]': smDown, '': tablet }"
        class="xflex xflex-row xgap-x-[20px] md:xmt-[2em] xmt-[1em] md:xjustify-start xjustify-center xitems-center"
      >
        <v-img contain class="xmax-w-[135px]" :src="mesasixFront"></v-img>
        <v-img contain class="xmax-w-[135px]" :src="slackFront"></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      homeFront: require("@/assets/covers/home.svg"),
      mesasixFront: require("@/assets/icons/mesasix-long.svg"),
      slackFront: require("@/assets/icons/slack-long.svg"),
      windowH: 0,
    };
  },
  computed: {
    ...mapGetters("appconfigs", ["app_trial_days"]),
  },
  mounted() {
    this.onResize();
  },

  methods: {
    onResize() {
      this.windowH = window.innerHeight;
    },
  },
};
</script>

<style lang="scss" scoped></style>
