<template>
  <v-container
    class="xmin-h-screen xmx-auto xflex xflex-col xitems-center xjustify-stretch"
    :class="{ '!xbg-none': smDown }"
    id="install"
    :style="{
      background: `url(${topLeft}) 100px top no-repeat, url(${rightBottom}) right bottom no-repeat, url(${leftBottom}) 100px bottom no-repeat`,
      backgroundColor: '#F3F3F3',
    }"
  >
    <div
      class="xflex xflex-col xw-full xpy-[2em] xpx-[2em] xjustify-center xitems-center"
    >
      <h2 class="install-label">TEAM REAUTHENTICATION</h2>
      <p class="install-description md:xw-8/12 xmx-auto xmy-2">
        Before integrating Mesasix Time Tracker to Slack, you will need to login
        using Slack and authorize the application. You will also need to have a
        non-restricted access to your Slack team in order to reauthenticate.
      </p>
    </div>
    <SetupInstruction
      class="md:!xw-10/12 xw-full xmx-auto"
      title="TEAM REAUTHENTICATION"
    ></SetupInstruction>

    <div class="xflex xflex-col xitems-center xjustify-center">
      <span
        class="hover:xtext-purple-600 xmy-2 xcursor-pointer primary--text"
        @click="openLegalities"
      >
        Read Terms & Conditions
      </span>
      <v-checkbox
        v-model="agree"
        class="my-2"
        label="Accept Terms & Conditions"
      ></v-checkbox>
      <a
        :class="[
          !agree ? 'xbg-[#575557] xpointer-events-none' : 'xbg-[#4a154b]',
        ]"
        class="xinline-flex xitems-center xmin-w-[280px] xjustify-center xh-[60px] xborder-0 xrounded-[4px]"
        :href="addToSlackUrl"
        style="
          color: #fff;
          font-family: Lato, sans-serif;
          font-size: 18px;
          font-weight: 600;
          text-decoration: none;
        "
      >
        <v-avatar tile size="25" class="mr-3">
          <v-img contain :src="slackLogo"></v-img>
        </v-avatar>
        <span>Reauthenticate Slack</span>
      </a>
    </div>

    <SuccessInstallationModal></SuccessInstallationModal>
  </v-container>
</template>

<script>
import httpBuildQuery from "http-build-query";
import SetupInstruction from "./components/SetupInstruction.vue";
import { mapGetters, mapMutations } from "vuex";
import SuccessInstallationModal from "../GlobalComponents/Modals/SuccessInstallationModal.vue";
export default {
  data() {
    return {
      team: null,
      agree: false,
      installing: false,
      topLeft: require("@/assets/covers/feature-t.svg"),
      leftBottom: require("@/assets/covers/feature-lb.svg"),
      rightBottom: require("@/assets/covers/feature-rb.svg"),
      slackLogo: require("@/assets/slack/slack-logo.svg"),
      app_scope:
        "channels:read,chat:write,commands,team:read,users:read,users:read.email",
      user_scope: "",
    };
  },
  watch: {
    "$route.params.team": {
      handler: function (val) {
        this.team = val;
      },
      immediate: true,
    },
    "$route.query.code": {
      handler: function (val) {
        val && this.reinstallAppToUserWorkspace(val);
      },
      immediate: true,
    },
    installing: {
      handler: function (val) {
        this.appLoader(val, "Reauthenticating to Slack...Please wait...");
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.installing)
      this.appLoader(
        this.installing,
        "Reauthenticating to Slack...Please wait..."
      );
  },
  computed: {
    ...mapGetters("appconfigs", ["slack_client_id"]),
    reinstall_redirect_uri() {
      return `${window.location.origin}/reauthenticate/${this.team}`;
    },
    addToSlackUrl() {
      return `https://slack.com/oauth/v2/authorize?${httpBuildQuery({
        client_id: this.slackApp.client_id,
        scope: this.app_scope,
        user_scope: this.user_scope,
        redirect_uri: this.reinstall_redirect_uri,
        team: this.team,
      })}`;
    },
  },
  methods: {
    ...mapMutations(["set_newInstall", "set_step1", "set_step2"]),
    openLegalities() {
      this.$event.$emit("open-legalities", true);
    },
    async reinstallAppToUserWorkspace(code) {
      this.installing = true;
      await this.$auth.refreshCsrfToken();
      this.$axios
        .post("api/auth/slack/reinstall-app", {
          code: code,
          redirect_uri: this.reinstall_redirect_uri,
        })
        .then(({ data }) => {
          this.appLoader(false);
          this.appToast("Success! Reauthenticated to Slack", "success");
          this.set_newInstall(true);
          this.set_step1(true);
          this.set_step2(true);
          this.$can.fetchPermissions();
        })
        .catch((err) => {
          console.error("Error", err);
        })
        .finally(() => {
          this.installing = false;
        });
    },
  },
  components: { SetupInstruction, SuccessInstallationModal },
};
</script>

<style lang="scss" scoped>
#install {
  .install-label {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.43em;
    color: #a240e3;
  }
  .install-plan {
    font-weight: 700;
    font-size: 42px;
    line-height: 51px;
    text-align: center;
    color: #000000;
  }
  .install-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: #616161;
  }
  ul {
    padding: 0 !important;

    li {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
  }
}
</style>
