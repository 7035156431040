<template>
  <div
    id="faq"
    :class="[smDown ? '!xbg-none' : '']"
    class="xflex xflex-col xw-full xcontainer xmx-auto xmin-h-[95vh] xpb-[3em]"
    :style="{
      background: `url(${topLeft}) 100px top no-repeat, url(${leftBottom}) right bottom no-repeat`,
      backgroundColor: '#F3F3F3',
    }"
  >
    <div
      class="xflex xflex-col xw-full xpy-[2em] xpx-[2em] xjustify-center xitems-center"
    >
      <h2
        class="xtext-[#a240e3] xfont-[900] xleading-[160%] xtext-[13px] xtracking-[0.43em]"
      >
        FAQ
      </h2>
      <h1 class="xfont-[700] xtext-[42px] xleading-[51px]">
        Frequently Asked Questions
      </h1>
    </div>
    <div class="md:xmax-w-[1100px] xmax-w-[95%] xmx-auto xw-full">
      <General></General>
    </div>
  </div>
</template>

<script>
import General from "./tabs/General.vue"; 
import HowTo from "./tabs/HowTo.vue";
export default {
  data() {
    return {
      tab: "general",
      topLeft: require("@/assets/covers/feature-t.svg"),
      leftBottom: require("@/assets/covers/feature-lb.svg"),
      rightBottom: require("@/assets/covers/feature-rb.svg"),
    };
  },
  components: { General, HowTo },
  methods: {
    onResize() {
      this.windowH = window.innerHeight;
    },
  },
  mounted() {
    this.onResize();
  },
};
</script>

<style lang="scss" scoped></style>
