<template>
  <div class="xflex xflex-col xw-full">
    <v-card
      flat
      class="xflex xflex-col xbg-white xrounded-b-[10px] xrounded-tr-[10px] xoverflow-hidden xw-full"
    >
      <v-card-title
        :class="[
          mdUp || tablet
            ? '!xflex-row xflex-nowrap xjustify-between'
            : '!xflex-col',
        ]"
        class="xflex xmb-[1em] xgap-2 xitems-center"
      >
        <KirbyMonthPicker
          @input="navigate"
          :value="selectedYearMonth"
          no-future
          :class="[mdUp ? '' : 'xw-full']"
        ></KirbyMonthPicker>
        <div
          class="xflex xflex-row md:xflex-nowrap xflex-wrap xgap-[0.5em]"
          v-if="laptopUp"
        >
          <!-- <btn-tooltip
            tip="Add Paid Time Off"
            color="primary"
            :disabled="false"
            :loading="false"
            @click="addPto"
            class="text-none"
          >
            <v-icon left> mdi-plus </v-icon> Add PTO
          </btn-tooltip>
          <btn-tooltip
            tip="Add Time"
            color="primary"
            :disabled="false"
            :loading="false"
            @click="addTime"
            class="text-none"
          >
            <v-icon left> mdi-plus </v-icon> Add Time
          </btn-tooltip> -->
          <btn-export
            v-if="$can.and('download_record')"
            :api-csv="`api/records/my/${selectedYearMonth}/csv`"
            :api-pdf="`api/records/my/${selectedYearMonth}/pdf`"
            :api-jpg="`api/records/my/${selectedYearMonth}/jpg`"
            :filename="`${user.real_name}-${selectedYearMonth}`"
          ></btn-export>
        </div>
        <div
          class="xflex xflex-row xjustify-end xitems-center xgap-[5px] xw-full !xmy-[5px]"
          v-else
        >
          <v-spacer></v-spacer>
          <!-- <btn-list
            icon="mdi-chevron-down"
            btn-color="primary"
            text-color="primary"
            outlined
          >
            <template v-slot:label>
              <v-icon small color="primary">mdi-form-dropdown</v-icon>
            </template>
            <v-list>
              <v-list-item v-if="$can.and('manage_user_ptos')" @click="addPto">
                <v-list-item-title>Add PTO</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="$can.and('manage_user_time')" @click="addTime">
                <v-list-item-title>Add Time</v-list-item-title>
              </v-list-item>
            </v-list>
          </btn-list> -->
          <btn-export
            v-if="$can.and('download_record')"
            :api-csv="`api/records/my/${selectedYearMonth}/csv`"
            :api-pdf="`api/records/my/${selectedYearMonth}/pdf`"
            :api-jpg="`api/records/my/${selectedYearMonth}/jpg`"
            :filename="`${user.real_name}-${selectedYearMonth}`"
          ></btn-export>
        </div>
      </v-card-title>
      <v-card flat class="md:xpb-[60px] xmin-h-[75vh] xpb-[100px] xpx-[1em]">
        <v-progress-linear
          indeterminate
          v-if="fetching"
          :height="10"
          class="my-1 xw-full"
          color="primary"
        ></v-progress-linear>
        <v-data-iterator
          :items="sortedItems"
          :items-per-page="31"
          :single-expand="false"
          hide-default-footer
        >
          <template v-slot:no-data>
            <app-empty
              class="xh-[70vh]"
              :text="`No record found for ${humanSelected}`"
            ></app-empty>
          </template>
          <template v-slot:default="{ items }">
            <v-card
              outlined
              v-for="(item, i) in items"
              :key="i"
              flat
              :color="!item.expand ? '#FAFAFA' : '#F1F1F1'"
              class="xrounded-[10px] xfont-semibold xtext-[15px] xmb-[10px] xborder-[1px] xborder-solid xborder-[#f1f1f1]"
            >
              <v-card-title
                :class="{ 'xbg-[#F5f5f5]': item.expand }"
                class="xflex xcursor-pointer !xw-full hover:xbg-gray-300 xgap-x-[1em] hover:xbg-opacity-30 xflex-row xjustify-between xtext-[16px] xitems-center"
                @click="() => (item.expand = !item.expand)"
              >
                <div
                  :class="[tabletUp ? 'xflex-row' : 'xflex-col']"
                  class="xflex xflex-1 xjustify-between xitems-center"
                >
                  <div
                    class="xw-7/12 xflex xflex-col xjustify-start xitems-start"
                  >
                    {{ item.range_from | format("ddd, MMMM D, YYYY") }}
                    <chip-pto
                      x-small
                      class="xmt-[5px] xw-fit"
                      v-if="item.summary.is_pto"
                      :approved="item.summary.status == 'approved'"
                    ></chip-pto>
                    <chip-at
                      x-small
                      class="xmt-[5px] xw-fit"
                      v-if="item.summary.is_added"
                      :approved="item.summary.status == 'approved'"
                    ></chip-at>
                  </div>
                  <div
                    class="xflex xflex-1 xgap-x-[0.5em] xflex-row xjustify-start xitems-start"
                  >
                    <div
                      class="xw-6/12 xflex xflex-col xjustify-start xitems-stretch"
                    >
                      <span class="xleading-[20px] xtext-[12px] xfont-[600]">
                        Work Time
                      </span>
                      <span class="md:xtext-[16px] xtext-[13px]">
                        {{
                          item.summary.works
                            | secToHuman(
                              tabletDown ? " hrs" : " hours",
                              tabletDown ? " mins" : " minutes"
                            )
                        }}
                      </span>
                    </div>
                    <div
                      class="xw-6/12 xflex xflex-col xjustify-start xitems-stretch"
                    >
                      <span class="xleading-[20px] xtext-[12px] xfont-[500]">
                        Break Time
                      </span>
                      <span class="md:xtext-[16px] xtext-[13px]">
                        {{
                          item.summary.breaks
                            | secToHuman(
                              tabletDown ? " hrs" : " hours",
                              tabletDown ? " mins" : " minutes"
                            )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <icon-tooltip
                  v-if="$can.and('manage_own_time')"
                  tip="Delete Shift"
                  icon="mdi-delete-clock-outline"
                  color="base_100"
                  clickable
                  class="xmr-[2em]"
                  @click="handleDeleteShift(item)"
                >
                </icon-tooltip>
                <v-icon color="#000">
                  {{ item.expand ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
              </v-card-title>
              <v-expand-transition>
                <div class="!xp-0 xbg-[#FCFCFC] xw-full" v-show="item.expand">
                  <ClockingChart
                    @edit-block="setEditBlock"
                    :has-edit="$can.and('manage_own_time')"
                    :has-delete="false"
                    :entry="item"
                  ></ClockingChart>
                </div>
              </v-expand-transition>
            </v-card>
          </template>
          <template v-slot:footer>
            <div
              class="xabsolute xbottom-0 md:xh-[60px] xh-[100px] xitems-center xw-full xpy-2 xpx-[1em] xtext-gray-600"
            >
              <div
                class="xflex xflex-1 md:xflex-row xflex-col xjustify-between xitems-center"
              >
                <div
                  class="xflex md:xw-7/12 xw-full xflex-row md:xjustify-start xjustify-center xtext-[13px] xgap-1 xitems-center"
                >
                  <span class="xfont-semibold">Slack Status :</span>
                  <v-icon color="gray">mdi-circle-medium</v-icon> Out
                  <v-icon color="#facc15">mdi-circle-medium</v-icon> Brb
                  <v-icon color="#92dac3">mdi-circle-medium</v-icon> Online
                </div>
                <v-divider class="xw-full xmy-1" v-if="smDown"></v-divider>
                <div
                  class="xflex md:xw-5/12 xgap-[0.5em] xw-full xflex-row xjustify-start xitems-center"
                >
                  <div
                    class="xw-6/12 xflex xflex-col xjustify-start xitems-stretch"
                  >
                    <span class="xleading-[20px] xtext-[12px] xfont-[500]">
                      Total Work Time
                    </span>
                    <span class="xfont-bold">
                      {{ totalWork | secToHuman(" hours", " minutes") }}
                    </span>
                  </div>
                  <div
                    class="xw-6/12 xflex xflex-col xjustify-start xitems-stretch"
                  >
                    <span class="xleading-[20px] xtext-[12px] xfont-[500]">
                      Total Break Time
                    </span>
                    <span class="xfont-bold">
                      {{ totalBreak | secToHuman(" hours", " minutes") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </v-data-iterator>
      </v-card>
    </v-card>

    <EditBlockTimeModal
      :block="activeBlock"
      @setChanges="sendToApi"
      @reset-block="activeBlock = null"
      v-model="open_edit_modal"
    ></EditBlockTimeModal>

    <AddTimeModal
      disable-select-user
      v-model="add_time_modal"
      :user="user"
    ></AddTimeModal>

    <AddPTOModal
      disable-select-user
      v-model="add_pto_modal"
      :user="user"
    ></AddPTOModal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import ClockingChart from "@/views/GlobalComponents/Charts/ClockingChart.vue";
import EditBlockTimeModal from "@/views/GlobalComponents/Modals/EditBlockTimeModal.vue";
import KirbyMonthPicker from "@/views/GlobalComponents/Forms/KirbyMonthPicker.vue";
import AddTimeModal from "@/views/GlobalComponents/Modals/AddTimeModal.vue";
import AddPTOModal from "@/views/GlobalComponents/Modals/AddPTOModal.vue";

export default {
  components: {
    ClockingChart,
    EditBlockTimeModal,
    KirbyMonthPicker,
    AddTimeModal,
    AddPTOModal,
  },
  data() {
    return {
      tab: null,
      activeBlock: null,
      add_time_modal: false,
      add_pto_modal: false,
      fetching: false,
      open_edit_modal: false,
      records: [],
      panels: [],
      defaultTab: {
        name: "app-my-record",
        params: { year_month: moment().format("YYYY-MM") },
      },
      selectedYearMonth: moment().format("YYYY-MM"),
      sortDesc: false,
      sortFn: (i) => i.range_from,
      headers: [
        {
          text: "Day",
          sortFn: (i) => i.range_from,
        },
        {
          text: "Work Time",
          sortFn: (i) => i.summary.works,
        },
        {
          text: "Break Time",
          sortFn: (i) => i.summary.breaks,
        },
      ],
    };
  },
  watch: {
    "$route.params.year_month": {
      handler: function (val) {
        this.selectedYearMonth = val;
        val && this.fetchSummary(val);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    sortedItems() {
      return _.orderBy(this.records, this.sortFn, [
        this.sortDesc ? "desc" : "asc",
      ]);
    },
    totalWork() {
      return _.sumBy(_.cloneDeep(this.records).map((i) => i.summary.works));
    },
    totalBreak() {
      return _.sumBy(_.cloneDeep(this.records).map((i) => i.summary.breaks));
    },
    humanSelected() {
      return !this.selectedYearMonth
        ? ""
        : moment(this.selectedYearMonth, "YYYY-MM").format("MMMM YYYY");
    },
  },
  mounted() {
    this.fetchWorkspaceProjects(this.user.team_id);
    this.$event.$on("refetch-records", () =>
      this.fetchSummary(this.selectedYearMonth)
    );
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceProjects"]),
    navigate(val) {
      this.$router
        .push({
          name: "app-my-record",
          params: { year_month: val },
        })
        .catch(() => {});
    },
    sortBy(sortFn) {
      this.sortDesc = !this.sortDesc;
      this.sortFn = sortFn;
    },
    setEditBlock(block) {
      this.open_edit_modal = true;
      this.activeBlock = block;
    },
    handleDeleteShift(item) {
      this.appConfirmation(`Delete this shift? This cannot be undone!`, () => {
        this.$axios
          .post(`api/records/delete-shift`, {
            user_id: this.user.id,
            date: moment(item.summary.start).format("YYYY-MM-DD"),
          })
          .then(({ data }) => {
            this.fetchSummary(this.selectedYearMonth);
          });
      });
    },
    fetchSummary(year_month) {
      this.fetching = true;
      this.$axios
        .get(`api/records/my/${year_month}`)
        .then(({ data }) => {
          this.records = data;
        })
        .finally(() => (this.fetching = false));
    },
    sendToApi({ payload, reset }) {
      this.$axios
        .post(`api/records/my/edit-blocks`, payload)
        .then(({ data }) => {
          this.appToast(`Success! Changes submitted.`, "success");
          this.open_edit_modal = false;
          this.fetchSummary(this.selectedYearMonth);
        })
        .catch((err) => reset());
    },
    addPto() {
      this.add_pto_modal = true;
    },
    addTime() {
      this.add_time_modal = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
