<template>
  <v-card v-bind="$attrs" flat>
    <v-card-title
      class="xflex flex-row xjustify-between xitems-center xbg-transparent"
    >
      <span class="xfont-[600] xtext-[18px] xleading-[22px] xtext-[#000]">
        Members
        <v-chip
          small
          color="#fff"
          class="xfont-[500] xtext-[12px] xleading-[15px] !xtext-[#9A9A9A]"
        >
          {{ counts }}
        </v-chip>
      </span>
      <router-link :to="usersPage" v-if="$can.and('view_team_info')">
        <span
          class="xtext-[13px] xfont-[400] hover:xtext-[#7A38A3] xtext-[#A8A8A8]"
        >
          View All
        </span>
      </router-link>
    </v-card-title>
    <v-card-text
      class="xbg-[#fff] xborder-[1px] xborder-solid xborder-[#f1f1f1] md:!xmin-h-[600px] xmax-h-[calc(100vh-150px)] xoverflow-y-auto xrounded-[10px]"
    >
      <v-progress-linear
        indeterminate
        :height="10"
        class="my-1"
        v-if="fetching"
        color="primary"
      ></v-progress-linear>
      <v-list dense>
        <v-list-item
          v-for="(user, index) in users_status"
          :key="index"
          class="xborder-b-[1px] xpy-[0.5em] xborder-[#e5e5e5] xflex xflex-row xh-full xitems-center xjustify-center"
        >
          <v-badge
            :color="
              user.clocking_status.on_work
                ? `#13D40F`
                : user.clocking_status.on_break
                ? '#FCAA3F'
                : `#B8B8B8`
            "
            dot
            class="xmr-[2em]"
            offset-x="10"
            offset-y="10"
          >
            <v-avatar size="45">
              <v-img
                :alt="`${user.real_name} avatar`"
                :src="user.image_url"
              ></v-img>
            </v-avatar>
          </v-badge>
          <v-list-item-content>
            <v-list-item-title
              class="xtext-[14px] xfont-[400]"
              v-text="user.real_name"
            ></v-list-item-title>
            <v-list-item-subtitle
              class="xpy-[5px]"
              v-if="user.clocking_status.no_record"
            >
              No clocking record yet
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="xpt-[5px]"
              v-else-if="user.clocking_status.on_work"
            >
              <chip-in pill dots></chip-in>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="xpt-[5px]"
              v-else-if="user.clocking_status.on_break"
            >
              <chip-brb pill dots></chip-brb>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="xpt-[5px]" v-else>
              <chip-out pill dots></chip-out>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <icon-tooltip v-if="$can.and('view_team_info')"
              tip="View record"
              @click="goToUserRecord(user)"
              clickable
              size="30"
              icon="mdi-chevron-right"
            ></icon-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  inheritAttrs: false,
  data() {
    return {
      lower_bottom: require("@/assets/images/data-work.svg"),
      cover: require("@/assets/covers/covers (3).webp"),
      loading: false,
      users: [],
      usersPage: { name: "app-settings-general-all-users" },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", ["users_status", "fetching"]),
    counts() {
      return this.users_status.length;
    },
  },
  methods: {
    goToUserRecord(user) {
      this.$router.push({
        name: "app-user-record-monthly",
        params: { id: user.id, year_month: moment().format("YYYY-MM") },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
