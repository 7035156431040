<template>
  <v-card flat>
    <v-card
      v-if="user && workspace"
      flat
      class="xh-full !xrounded-[10px]"
      color="#fff"
    >
      <v-card-text
        class="xflex md:xflex-row xflex-col xjustify-between md:xpy-[3em] xitems-center md:xh-[160px]"
      >
        <div class="xflex xflex-col xjustify-start xitems-stretch xw-full">
          <h1
            class="xfont-[700] xmy-[10px] xtext-[#000] md:xtext-[30px] xtext-[15px] md:xleading-[37px] xleading-[20px]"
          >
            {{ randomGreet }},
            <span class="primary--text">{{ user.real_name }}</span>
          </h1>
          <span
            v-if="currentUserStatus"
            class="xfont-bold xtext-[#000] md:xtext-[16px] xtext-[14px] xleading-[30px]"
          >
            <span v-if="currentUserStatus.on_work">
              You are currently
              <chip-in small></chip-in>
              and working on
              <chip-in
                small
                :text="currentUserStatus.last_active_project"
              ></chip-in>
            </span>
            <span v-else-if="currentUserStatus.on_break">
              You are currently on
              <chip-brb text="break" small></chip-brb>
            </span>
            <span v-else-if="currentUserStatus.on_out">
              You are currently
              <chip-out small></chip-out>
              <template v-if="!currentUserStatus.no_record">
                and you last clocked out
                <chip-out
                  small
                  :text="currentUserStatus.last_log_datetime | from_now"
                ></chip-out>
              </template>
            </span>
          </span>
        </div>
      </v-card-text>
      <!-- <pre>{{ currentUserStatus }}</pre> -->
      <v-card-title
        class="!xfont-[600] xtext-[18px] xtext-[#2e2e2e] xleading-[22px]"
      >
        Account Metrics
      </v-card-title>
      <v-card-text
        :class="mobile ? 'xgrid-cols-1' : 'xgrid-cols-2'"
        class="xgrid xgap-[1em]"
      >
        <MetricBox
          class="xcol-span-1"
          title="Weekly Average Active Work Time"
          :value="average_works | secToHuman('hr', 'min')"
          color="#5143D9"
        ></MetricBox>
        <MetricBox
          class="xcol-span-1"
          title="Weekly Average Break Time"
          :value="average_breaks | secToHuman('hr', 'min')"
          color="#F96184"
        ></MetricBox>
        <!-- <MetricBox
          class="xcol-span-1"
          title="Average Active Work Time"
          :value="average_active_time | secToHuman('hr', 'min')"
          color="#F5B85D"
        ></MetricBox> -->
      </v-card-text>

      <v-card-title class="xfont-[600] xtext-[18px] xleading-[22px]">
        My Latest Record
      </v-card-title>

      <v-card
        flat
        v-if="item"
        outlined
        color="#f5f5f5"
        class="xrounded-none xfont-semibold xtext-[15px] xmb-[5px] xborder-none"
      >
        <v-card-title
          class="xflex !xw-full xbg-[#FCFCFC] xgap-x-[1em] hover:xbg-opacity-30 xflex-row xjustify-between xtext-[16px] xitems-center"
        >
          <div
            :class="[laptopUp ? 'xflex-row' : 'xflex-col']"
            class="xflex xflex-1 xjustify-between xitems-center"
          >
            <div :class="[laptopUp ? 'xw-5/12' : 'xw-full']">
              {{ item.range_from | format("ddd, MMMM D, YYYY") }}
              <v-chip
                label
                small
                outlined
                class="xml-[10px]"
                color="red"
                v-if="item.summary.is_pto"
              >
                PTO
              </v-chip>
            </div>
            <div
              :class="[laptopUp ? 'xw-7/12' : 'xw-full']"
              class="xflex xflex-1 xgap-x-[0.5em] xflex-row xjustify-start xitems-start"
            >
              <div
                class="xw-6/12 xflex xflex-col xjustify-start xitems-stretch"
              >
                <span class="xleading-[20px] xtext-[12px] xfont-[600]">
                  Work Time
                </span>
                <span class="md:xtext-[16px] xtext-[13px]">
                  {{ item.summary.works | secToHuman(" hours", " minutes") }}
                </span>
              </div>
              <div
                class="xw-6/12 xflex xflex-col xjustify-start xitems-stretch"
              >
                <span class="xleading-[20px] xtext-[12px] xfont-[500]">
                  Break Time
                </span>
                <span class="md:xtext-[16px] xtext-[13px]">
                  {{ item.summary.breaks | secToHuman(" hours", " minutes") }}
                </span>
              </div>
            </div>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <ClockingChartSolo
          :has-edit="false"
          :has-delete="false"
          :entry="item"
        ></ClockingChartSolo>
      </v-card>
      <app-empty
        v-else
        text="No record yet"
        class="xpy-4 xmx-[1em]"
        outlined
      ></app-empty>
      <!-- <pre>{{ item }}</pre> -->
    </v-card>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import ClockingChartSolo from "@/views/GlobalComponents/Charts/ClockingChartSolo.vue";
import MetricBox from "./MetricBox.vue";

export default {
  components: { ClockingChartSolo, MetricBox },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", ["workspace", "users_status"]),
    ...mapGetters("currents", [
      "average_breaks",
      "average_works",
      "average_active_time",
      "as_of",
    ]),
    currentUserStatus() {
      const user = this.users_status.find((i) => i.id === this.user.id);
      return user ? user.clocking_status : null;
    },
    randomGreet() {
      return _.sample(this.greetings);
    },
    periodicGreeting() {
      let hour = moment().hour();
      let timeOfDay = "Good day";
      if (hour >= 5 && hour <= 11) {
        timeOfDay = "Good Morning";
      } else if (hour > 11 && hour < 13) {
        timeOfDay = "Good Noon";
      } else if (hour >= 13 && hour < 18) {
        timeOfDay = "Good Afternoon";
      } else if (hour > 18 && hour <= 20) {
        timeOfDay = "Good Evening";
      } else if (hour > 20 && hour < 24) {
        timeOfDay = "Good Night";
      } else if (hour > 0 && hour <= 4) {
        timeOfDay = "Good Late Night";
      }
      return timeOfDay;
    },
    greetings() {
      return [
        "Welcome back",
        "Hey",
        "Hello",
        "Hi",
        "Howdy",
        "Bonjour",
        "Aloha",
        "Namaste",
        "Cheers",
        this.periodicGreeting,
      ];
    },
  },
  data() {
    return {
      item: null,
    };
  },
  created() {
    this.fetchLatestRecord();
    this.fetchAverageStatus(this.user.id);
  },
  methods: {
    ...mapActions("currents", ["fetchAverageStatus"]),
    fetchLatestRecord() {
      this.$axios.get(`api/records/my/latest-record`).then(({ data }) => {
        this.item = data;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
