import request from '@/plugins/requests'

export default {
    namespaced: true,
    state: {
        workspace: null,
        allusers: [],
        users: [],
        users_status: [],
        superiors: [],
        projects: [],
        subscription: null,
        regions: [],
        sites: [],
        dataCenters: [],
        statistics: {
            fetch: false,
            new_users_count: 0,
            users_count: 0,
            regions_count: 0,
            sites_count: 0,
            data_centers_count: 0,
        },
        syncing: false,
        fetching: false
    },
    getters: {
        fetching: state => state.fetching,
        statistics: state => state.statistics,
        syncing: state => state.syncing,
        workspace: (state) => state.workspace,
        allusers: (state) => state.allusers,
        users: (state) => state.users,
        projects: (state) => state.projects,
        subscription: state => state.subscription,
        users_status: state => state.users_status,
        regions: state => state.regions,
        sites: state => state.sites,
        dataCenters: state => state.dataCenters,
        superiors: state => state.superiors
    },
    mutations: {
        set_statistics: (state, payload) => (state.statistics = payload),
        set_fetching: (state, payload) => (state.fetching = payload),
        set_syncing: (state, payload) => (state.syncing = payload),
        set_workspace: (state, payload) => (state.workspace = payload),
        set_allusers: (state, payload) => (state.allusers = payload),
        set_users: (state, payload) => (state.users = payload),
        set_superiors: (state, payload) => (state.superiors = payload),
        set_users_status: (state, payload) => (state.users_status = payload),
        set_projects: (state, payload) => (state.projects = payload),
        add_project: (state, payload) => (state.projects.push(payload)),
        set_subscription: (state, payload) => (state.subscription = payload),
        set_regions: (state, payload) => (state.regions = payload),
        set_sites: (state, payload) => (state.sites = payload),
        set_dataCenters: (state, payload) => (state.dataCenters = payload),
        delete_region: (state, id) => {
            let index = state.regions.findIndex(i => i.id == id)
            if (~index) state.regions.splice(index, 1)
        },
        delete_site: (state, id) => {
            let index = state.sites.findIndex(i => i.id == id)
            if (~index) state.sites.splice(index, 1)
        },
        delete_dataCenter: (state, id) => {
            let index = state.dataCenters.findIndex(i => i.id == id)
            if (~index) state.dataCenters.splice(index, 1)
        },
    },
    actions: {
        fetchWorkspaceInfo: ({ commit }, id) => {
            commit('set_fetching', true)
            request.get(`api/workspaces/${id}?with=meta`)
                .then(({ data }) => {
                    commit('set_workspace', data)
                })
                .finally(() => commit('set_fetching', false))
        },
        fetchWorkspaceSubscription: ({ commit }, id) => {
            commit('set_fetching', true)
            request.get(`api/workspaces/${id}/subscription`)
                .then(({ data }) => {
                    commit('set_subscription', data)
                })
                .finally(() => commit('set_fetching', false))
        },
        fetchWorkspaceSuperiors: ({ commit }, id) => {
            commit('set_fetching', true)
            request.get(`api/workspaces/${id}/users/superiors?with=role,roles`)
                .then(({ data }) => {
                    commit('set_superiors', data)
                })
                .finally(() => commit('set_fetching', false))
        },
        fetchWorkspaceUsers: ({ commit }, id) => {
            commit('set_fetching', true)
            request.get(`api/workspaces/${id}/users?with=role,roles`)
                .then(({ data }) => {
                    commit('set_users', data)
                })
                .finally(() => commit('set_fetching', false))
        },
        fetchWorkspaceUsersStatus: ({ commit }, id) => {
            commit('set_fetching', true)
            request.get(`api/workspaces/${id}/users?with=role&status=1`)
                .then(({ data }) => {
                    commit('set_users_status', data)
                })
                .finally(() => commit('set_fetching', false))
        },
        fetchWorkspaceProjects: ({ commit }, id) => {
            commit('set_fetching', true)
            request.get(`api/workspaces/${id}/projects`)
                .then(({ data }) => {
                    commit('set_projects', data)
                })
                .finally(() => commit('set_fetching', false))
        },
        fetchWorkspaceAllUsers: ({ commit }, id) => {
            commit('set_fetching', true)
            request.get(`api/workspaces/${id}/users?with=role,roles&include_trashed=true`)
                .then(({ data }) => {
                    commit('set_allusers', data)
                })
                .finally(() => commit('set_fetching', false))
        },
        syncWorkspaceToSlack: ({ commit }, { id, cb }) => {
            commit('set_syncing', true)
            request.post(`api/workspaces/sync-to-slack`, { id: id })
                .then(({ data }) => {
                    commit('set_workspace', data)
                    if (typeof cb === 'function') cb(data)
                })
                .finally(() => commit('set_syncing', false))
        },
        syncWorkspaceUsersToSlack: ({ commit }, { id, cb }) => {
            commit('set_syncing', true)
            request.post(`api/workspaces/users/sync-to-slack`, { id: id })
                .then(({ data }) => {
                    commit('set_users', data)
                    if (typeof cb === 'function') cb(data)
                })
                .finally(() => commit('set_syncing', false))
        },
        fetchWorkspaceRegions: ({ commit }, { id, cb }) => {
            commit('set_fetching', true)
            request.get(`api/workspaces/${id}/regions?all=1`)
                .then(({ data }) => {
                    commit('set_regions', data)
                    if (typeof cb === 'function') cb(data)
                })
                .finally(() => commit('set_fetching', false))
        },
        fetchWorkspaceSites: ({ commit }, { id, cb }) => {
            commit('set_fetching', true)
            request.get(`api/workspaces/${id}/sites?all=1`)
                .then(({ data }) => {
                    commit('set_sites', data)
                    if (typeof cb === 'function') cb(data)
                })
                .finally(() => commit('set_fetching', false))
        },
        fetchWorkspaceDataCenters: ({ commit }, { id, cb }) => {
            commit('set_fetching', true)
            request.get(`api/workspaces/${id}/data-centers?all=1`)
                .then(({ data }) => {
                    commit('set_dataCenters', data)
                    if (typeof cb === 'function') cb(data)
                })
                .finally(() => commit('set_fetching', false))
        },
        fetchWorkspaceStatistics({ commit }, id) {
            commit('set_fetching', true)
            request
                .get(`api/workspaces/${id}/statistics`)
                .then(({ data }) => {
                    commit('set_statistics', data)
                })
                .finally(() => commit('set_fetching', false))
        },
    }
}