<template>
  <v-navigation-drawer
    app
    :light="light"
    v-model="sidebar"
    color="secondary"
    :temporary="temporary"
    left
    :mobile-breakpoint="1023"
  >
    <template v-slot:prepend>
      <v-list-item
        class="xbg-no-repeat xbg-cover xh-[70px]"
        :style="{
          backgroundImage: `url(${bgWave})`,
        }"
      >
        <v-list-item-content>
          <v-avatar
            tile
            @click="$router.push({ name: 'admin-dashboard' }).catch(() => {})"
          >
            <v-img contain :src="logoLongLight"></v-img>
          </v-avatar>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-list class="xmy-0 xpy-0" v-model="active" flat>
      <v-hover
        v-for="item in items"
        :key="`main-${item.title}`"
        v-slot:default="{ hover }"
      >
        <v-list-item
          class="xh-[50px] xm-0 xp-0 sidebar-item"
          :class="[
            hover ? 'sidebar-item-hover' : '',
            isActive(item) ? 'sidebar-item-active' : '',
          ]"
          :to="item.route"
        >
          <img :src="isActive(item) ? borderLeftActive : borderLeftInactive" />
          <div
            class="xflex xflex-row xw-full xml-[30px] xh-full xjustify-start xitems-center xgap-x-[.5em]"
          >
            <v-icon :color="isActive(item) || hover ? '#7A38A3' : '#9A9A9A'">
              {{ item.icon }}
            </v-icon>
            <span
              class="xfont-[600] xtext-[15px] xleading-[18px]"
              :class="
                isActive(item) || hover ? 'xtext-[#7A38A3]' : 'xtext-[#9A9A9A]'
              "
            >
              {{ item.title }}
            </span>
          </div>
        </v-list-item>
      </v-hover>
    </v-list>
    <!-- {{ $route.meta }} -->
  </v-navigation-drawer>
</template>

<script>
export default {
  computed: {
    sidebar: {
      get() {
        return this.mdUp && !this.tablet
          ? true
          : this.$store.getters["appconfigs/sidebar"];
      },
      set(val) {
        this.$store.commit("appconfigs/set_sidebar", val);
      },
    },
    temporary: {
      get() {
        return this.mdUp ? false : this.$store.getters["appconfigs/temporary"];
      },
      set(val) {
        if (!this.mdUp) this.$store.commit("appconfigs/set_temporary", val);
      },
    },
    light: {
      get() {
        return this.$store.getters["appconfigs/light"];
      },
      set(val) {
        this.$store.commit("appconfigs/set_light", val);
      },
    },
  },
  data() {
    return {
      borderLeftInactive: require("@/assets/border-left-inactive.svg"),
      borderLeftActive: require("@/assets/border-left-active.svg"),
      bgWave: require("@/assets/covers/waves-animated-2.svg"),
      active: null,
      items: [
        {
          title: "Dashboard",
          route: { name: "admin-dashboard" },
          icon: "mdi-desktop-mac-dashboard",
        },
        {
          title: "Configuration",
          route: { name: "admin-configs" },
          icon: "mdi-cog",
        },
        {
          title: "Teams",
          route: {
            name: "admin-subscribers-subscribed",
            params: { filter: "subscribed" },
          },
          icon: "mdi-account-group",
          alias: [
            "admin-workspace-info",
            "admin-workspace-members",
            "admin-workspace-configs",
            "admin-workspace-bot-responses",
            "admin-subscribers-subscribed",
            "admin-subscribers-cancelled",
            "admin-subscribers-exempted",
            "admin-subscribers-disabled",
            "admin-subscribers-ongoing-free-trial",
            "admin-subscribers-expired-free-trial",
          ],
        },
        {
          title: "Database",
          route: { name: "admin-database" },
          icon: "mdi-database-cog-outline",
        },
        {
          title: "Roles",
          route: { name: "admin-roles" },
          icon: "mdi-account-supervisor-circle-outline",
        },
        {
          title: "Feedbacks",
          route: { name: "admin-feedbacks" },
          icon: "mdi-sign-real-estate",
        },
        {
          title: "Supports",
          route: { name: "admin-supports" },
          icon: "mdi-headset-dock",
        },
        {
          title: "Back Logs",
          route: { name: "admin-backlogs" },
          icon: "mdi-bug-outline",
        },
      ],
    };
  },
  methods: {
    isActive(item) {
      if (item.hasOwnProperty("alias"))
        return item.alias.includes(this.$route.name);
      return this.$route.name === item.route.name;
    },
  },
};
</script>

<style lang="scss">
.v-navigation-drawer__content {
  background: #515151 !important;
  .v-list-item--active {
    background: transparent;
  }
}
</style>
