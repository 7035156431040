<template>
  <v-card min-height="100vh" flat color="transparent" class="xw-full" :class="[tabletDown ? '' : 'xp-[1em]']">
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">
        Paid Time Off Requests
      </h1>
    </v-card-title>
    <v-card-text>
      <app-tabs :tabs="tabs" optional>
        <router-view :key="$route.fullPath"></router-view>
      </app-tabs>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      statistics: { all_count: 0, pending_count: 0 },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", ["allusers"]),
    selectedType() {
      return this.$route.name.includes("app-pto-all") ? "all" : "pending";
    },
    tabs() {
      return [
        {
          to: {
            name: "app-pto-all-monthly",
            params: {
              year_month: moment().format("YYYY-MM"),
            },
          },
          name: "All",
          alias: [
            "app-pto-all-monthly",
            "app-pto-all-weekly",
            "app-pto-all-custom",
          ],
          counts: this.statistics.all_count || 0,
        },
        {
          to: {
            name: "app-pto-pending-monthly",
            params: {
              year_month: moment().format("YYYY-MM"),
            },
          },
          name: "Pending",
          alias: [
            "app-pto-pending-monthly",
            "app-pto-pending-weekly",
            "app-pto-pending-custom",
          ],
          counts: this.statistics.pending_count || 0,
        },
      ];
    },
  },
  created() {
    this.fetchStatistics();
    if (!this.allusers.length) this.fetchWorkspaceAllUsers(this.user.team_id);
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceAllUsers"]),
    fetchStatistics() {
      this.loading = true;
      this.$axios
        .get(`api/ptos/statistics`)
        .then(({ data }) => {
          this.statistics = data;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
