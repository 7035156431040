<template>
  <v-autocomplete
    hide-details="auto"
    dense
    v-model="selected"
    outlined
    :clearable="clearable"
    item-text="real_name"
    multiple
    item-value="id"
    v-bind="$attrs"
    append-icon="mdi-chevron-down"
    :items="lessExcepts"
  >
    <template v-slot:item="{ item }">
      <span>
        <v-list-item>
          <v-list-item-avatar class="xrounded-[10px]">
            <v-img contain :src="item.image_url"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="xfont-semibold font-weight-bold">
              {{ item.real_name | ucwords }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.job_title }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
    </template>
    <template v-slot:selection="{ item }">
      <v-chip color="transparent" label small class="mr-auto w-full my-1">
        <v-avatar tile class="mr-3 !xrounded-[10px]">
          <v-img contain :src="item.image_url"></v-img>
        </v-avatar>
        <span class="xfont-[500] xtext-[15px]">{{ item.real_name }}</span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
export default {
  inheritAttrs: false,
  props: {
    value: { type: Array, default: () => [] },
    clearable: { type: Boolean, default: false },
    except: { type: Array, default: () => [] },
  },
  data() {
    return {
      selected: [],
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.selected = val;
      },
      immediate: true,
      deep: true,
    },
    selected: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", ["allusers"]),
    lessExcepts() {
      return _.cloneDeep(this.allusers).filter(
        (i) => !this.except.includes(i.id)
      );
    },
  },
  mounted() {
    if (!this.allusers.length) this.fetchWorkspaceAllUsers(this.user.team_id);
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceAllUsers"]),
  },
};
</script>

<style lang="scss"></style>
