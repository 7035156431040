<template>
  <v-card
    color="transparent"
    min-height="100vh"
    flat
    :class="[tabletDown ? '' : 'xp-[1em]']"
  >
    <v-card-text>
      <div
        class="xflex xflex-row xjustify-start xitems-center xgap-x-[1em] xmb-[1.5em]"
      >
        <v-icon left>mdi-chevron-left</v-icon>
        <span @click="goBack" class="xcursor-pointer hover:xtext-purple-600">
          Team Record
        </span>
        <span v-if="data">|</span>
        <span v-if="data">{{ data.real_name }}</span>
      </div>
      <div
        class="xflex md:xflex-row xflex-col xjustify-between xitems-center xgap-[0.5em]"
        v-if="data"
      >
        <div
          class="xflex md:xw-6/12 xw-full xflex-row xgap-x-[1em] xjustify-start xitems-center"
        >
          <v-avatar size="100">
            <v-img contain :src="data.image_url"></v-img>
          </v-avatar>
          <div class="xflex xflex-col xgap-y-[5px]">
            <h1
              class="xfont-[600] xtext-[24px] xleading-[29px] xtext-[#2E2E2E]"
            >
              {{ data.real_name }}
            </h1>
            <span
              class="xfont-[400] xtext-[16px] xleading-[19px] xtext-[#2E2E2E]"
            >
              {{ data.email }}
            </span>
            <chip-in
              class="xflex xjustify-center xflex-col xw-fit xpx-[1.5em]"
              dots
              v-if="data.status.on_work"
              label
              text="IN"
            ></chip-in>
            <chip-brb
              class="xflex xjustify-center xflex-col xw-fit xpx-[1.5em]"
              dots
              v-else-if="data.status.on_break"
              label
              text="BRB"
            ></chip-brb>
            <chip-out
              class="xflex xjustify-center xflex-col xw-fit xpx-[1.5em]"
              dots
              v-else-if="data.status.on_out"
              label
              text="OUT"
            ></chip-out>
          </div>
        </div>

        <div
          class="xgrid md:xw-6/12 xw-full md:xgrid-cols-2 xgrid-cols-1 xgap-[1em]"
        >
          <div
            :class="laptopUp ? 'xflex-row' : 'xflex-col'"
            class="xcol-span-1 xflex xjustify-between xgap-x-[1em] xtext-[#fff] xbg-[#5143D9] xp-[1em] xrounded-md"
          >
            <div
              :class="laptopUp ? 'xw-6/12' : 'xw-full'"
              class="xfont-[600] xtext-[14px] xleading-[24px] xflex-col xflex xjustify-center xitems-center"
            >
              Weekly Average <br />
              Active Work Time
            </div>
            <div
              :class="laptopUp ? 'xw-6/12' : 'xw-full'"
              class="xflex-col xflex xjustify-start xgap-y-[5px] xitems-center"
            >
              <span class="xfont-[700] xtext-[24px] xtext-left">
                {{ averages.works | secToHuman("hr", "min") }}
              </span>
              <span>as of {{ averages.as_of }}</span>
            </div>
          </div>
          <div
            :class="laptopUp ? 'xflex-row' : 'xflex-col'"
            class="xcol-span-1 xflex xflex-row xjustify-between xgap-x-[1em] xtext-[#fff] xbg-[#F96184] xp-[1em] xrounded-md"
          >
            <div
              :class="laptopUp ? 'xw-6/12' : 'xw-full'"
              class="xfont-[600] xtext-[14px] xleading-[24px] xflex-col xflex xjustify-center xitems-center"
            >
              Weekly Average <br />
              Break Time
            </div>
            <div
              :class="laptopUp ? 'xw-6/12' : 'xw-full'"
              class="xflex-col xflex xjustify-start xgap-y-[5px] xitems-center"
            >
              <span class="xfont-[700] xtext-[24px] xtext-left">
                {{ averages.breaks | secToHuman("hr", "min") }}
              </span>
              <span>as of {{ averages.as_of }}</span>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <router-view :key="$route.fullPath"></router-view>
    <!-- <pre>{{ data }}</pre> -->
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      member: null,
      data: null,
      averages: {
        works: 0,
        breaks: 0,
        as_of: moment().format("LL"),
      },
    };
  },
  computed: {
    yearMonth() {
      return moment().format("YYYY-MM");
    },
  },
  watch: {
    "$route.params.id": {
      handler: function (val) {
        val && this.fetchData(val);
        val && this.fetchAverageStatus(val);
      },
      immediate: true,
    },
  },
  methods: {
    fetchData(id) {
      this.$axios.get(`api/user-records/${id}/analytics`).then(({ data }) => {
        this.data = data;
      });
    },
    fetchAverageStatus(id) {
      this.$axios
        .get(`api/user-records/${id}/average/weekly`)
        .then(({ data }) => {
          this.averages = {
            works: data.average_works,
            breaks: data.average_breaks,
            as_of: data.as_of,
          };
        });
    },
    goBack() {
      if (this.$route.query.redirect_uri) {
        this.$router.push({ path: this.$route.query.redirect_uri });
      } else
        this.$router.push({
          name: "app-team-record-monthly",
          params: { year_month: moment().format("YYYY-MM") },
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
