<template>
  <v-chip color="#b0000024" v-bind="$attrs">
    <div
      class="xpx-[8px] xpy-[2px] !xmin-w-[33px] xmin-h-[20px] xflex xjustify-center xitems-center"
    >
      <v-icon color="#FC3D39" v-if="dots" x-small left>mdi-circle</v-icon>
      <span class="xtext-text-out">{{ text }}</span>
    </div>
  </v-chip>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    dots: { type: Boolean, default: false },
    text: { type: String, default: "Out" },
  },
};
</script>

<style></style>
