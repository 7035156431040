<template>
  <v-data-table
    id="app-table"
    hide-default-footer
    disable-pagination
    disable-filtering
    :mobile-breakpoint="0"
    :disable-sort="smDown || disableSort"
    :headers="headings"
    :items="items"
    :loading="loading"
    v-bind="$attrs"
    @update:sort-desc="isSortedDesc"
    :header-props="headerProps"
    :items-per-page="-1"
  >
    <template v-slot:[`header.actions`]>
      <span></span>
    </template>

    <template v-slot:item="{ item, headers, index }">
      <tr
        :key="index"
        class="tr"
        @click.stop="$emit('click:row', item)"
        :class="[
          `tr-${index}`,
          index % 2 === 0 ? 'tr-even' : 'tr-odd',
          clickableRow ? 'xcursor-pointer' : '',
        ]"
      >
        <td
          v-for="(prop, tdIndex) in headers"
          :key="`td-${index}-${tdIndex}`"
          class="td"
          :class="[`td-${prop.value}`, prop.tdClass]"
        >
          <slot :name="prop.value" :item="item" :index="index">
            {{ item[prop.value] }}
          </slot>
        </td>
      </tr>
    </template>

    <template v-slot:progress>
      <v-progress-linear
        indeterminate
        :height="10"
        class="my-1"
        color="primary"
      ></v-progress-linear>
    </template>

    <template v-slot:no-data>
      <div
        class="xmin-h-[300px] xflex xflex-col xjustify-center xitems-center"
        :class="emptyClass"
      >
        <slot name="no-data">
          <div class="xflex xflex-col xjustify-center xitems-center">
            <v-avatar>
              <v-img contain :src="logoSquare" />
            </v-avatar>
            <div class="title primary--text" v-html="emptyText"></div>
          </div>
        </slot>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    headings: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    disableSort: { type: Boolean, default: false },
    emptyText: { type: String, default: "No data" },
    emptyClass: { type: String, default: "" },
    clickableRow: { type: Boolean, default: false },
  },
  data() {
    return {
      headerProps: { "sort-icon": "mdi-sort-ascending" },
    };
  },
  methods: {
    isSortedDesc(e) {
      // if (typeof e === undefined) this.headerProps = { "sort-icon": "mdi-menu-swap" };
      // else if (e === true) this.headerProps = { "sort-icon": "mdi-menu-up" };
      // else if (e === false) this.headerProps = { "sort-icon": "mdi-menu-down" };
    },
  },
};
</script>

<style lang="scss">
.v-application--is-ltr {
  .v-data-table {
    .v-data-table-header {
      background-color: #f5f5f5;

      span {
        color: #898989;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
      }
    }
    .v-data-table__wrapper {
      padding-bottom: 20px !important;
      table {
        thead {
          tr {
            th {
              &:last-child {
                border: none !important;
              }
              background: #f5f5f5 !important;
              border: none !important;

              span {
                font-weight: 700;
              }
            }
          }
        }
        tbody {
          tr {
            &.tr-even {
              &:hover {
                background: #fafafa !important;
              }
              background: #fafafa;
            }
            &.tr-odd {
              &:hover {
                background: #e5e5e5 !important;
              }
              background: #e5e5e5;
            }
            th {
              border: none !important;
            }
            td {
              border: none !important;
            }
          }
        }
      }
    }
  }
}
</style>
