<template>
  <app-modal
    :valid="valid"
    title="Add Paid Time Off (PTO)"
    v-model="open"
    max-width="800"
    :confirm-label="okLabel"
    @confirmed="submit"
  >
    <v-card flat color="transparent">
      <v-card-text>
        <div
          class="xflex xmb-[1em] md:xflex-row xflex-col xjustify-between xgap-[10px] xitems-stretch"
        >
          <div class="md:xw-full xmax-w-full xflex xflex-col">
            <label class="xfont-semibold xtext-[#2E2E2E]">
              User <sup class="xtext-red-600">*</sup>
            </label>
            <UserSelect
              required
              :disabled="disableSelectUser"
              class="xw-full"
              :rules="[requiredRule()]"
              v-model="selectedUser"
              return-object
              prepend-inner-icon="mdi-account-search"
            ></UserSelect>
          </div>
          <div class="md:xw-full xmax-w-full xflex xflex-col">
            <label class="xfont-semibold xtext-[#2E2E2E]">
              Base on Timezone <sup class="xtext-red-600">*</sup>
            </label>
            <TimezonePicker
              class="xw-full"
              :rules="[requiredRule()]"
              v-model="selectedTz"
            ></TimezonePicker>
          </div>
        </div>

        <div class="md:xw-full xmax-w-full xflex xflex-col">
          <label class="xfont-semibold xtext-[#2E2E2E]">
            Description <sup class="xtext-red-600">*</sup>
          </label>
          <v-textarea
            outlined
            flat
            placeholder="e.g Have to travel for a wedding... etc"
            rows="3"
            class="xw-full"
            :rules="[requiredRule()]"
            v-model="description"
          ></v-textarea>
        </div>
        <v-sheet
          color="transparent"
          class="xflex xflex-col xjustify-start xw-full"
        >
          <div
            v-if="mdUp"
            class="xw-full xmb-[5px] xh-[50px] xflex xbg-[#e6e6e6] xflex-row xjustify-between xitems-center"
          >
            <div
              class="xp-[5px] xfont-semibold md:xw-4/12 xw-4/12 xtext-[#2E2E2E] xtext-left"
            >
              PTO Request Date <sup class="xtext-red-600">*</sup>
            </div>
            <div
              class="xp-[5px] xfont-semibold md:xw-3/12 xw-4/12 xtext-[#2E2E2E] xtext-left"
            >
              Time Range <sup class="xtext-red-600">*</sup>
            </div>
            <div
              class="xp-[5px] xfont-semibold md:xw-4/12 xw-4/12 xtext-[#2E2E2E] xtext-left"
            >
              Time IN <sup class="xtext-red-600">*</sup>
            </div>
            <div
              class="xp-[5px] xfont-semibold md:xw-1/12 xw-4/12 xtext-[#2E2E2E] xtext-left"
            ></div>
          </div>
          <TimeRangeInput
            :duplicable="items.length === 0"
            v-model="firstItem"
            :deletable="false"
            @duplicate="duplicate(firstItem)"
          ></TimeRangeInput>

          <TimeRangeInput
            v-for="(item, index) in items"
            :key="index"
            deletable
            :duplicable="items.length - 1 === index"
            :value="items[index]"
            @input="items.splice(index, 1, $event)"
            @remove="items.splice(index, 1)"
            @duplicate="duplicate(item)"
          ></TimeRangeInput>

          <div
            :class="[mdUp ? 'xw-10/12 xmr-auto' : 'xw-full']"
            class="xflex xflex-col xmb-[5px] xjustify-center xitems-center"
          >
            <v-btn
              dark
              block
              depressed
              color="#7A38A3"
              class="text-none xfont-semibold !xborder-dotted xborder-[#353535] xborder-[2px]"
              @click="insert"
            >
              <v-icon left>mdi-plus</v-icon> Add more date
            </v-btn>
          </div>
        </v-sheet>
      </v-card-text>
      <!-- <pre>{{ user }}</pre> -->
    </v-card>
  </app-modal>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import UserSelect from "@/views/GlobalComponents/Forms/UserSelect.vue";
import TimezonePicker from "@/views/GlobalComponents/Forms/TimezonePicker.vue";
import CommandPicker from "@/views/GlobalComponents/Forms/CommandPicker.vue";
import TimeRangeInput from "@/views/GlobalComponents/Forms/TimeRangeInput.vue";

export default {
  props: {
    value: { type: Boolean, default: false },
    disableSelectUser: { type: Boolean, default: false },
    user: { type: [Object, String], default: undefined },
  },
  data() {
    return {
      open: false,
      submiting: false,
      selectedUser: null,
      selectedTz: moment.tz.guess(),
      description: null,
      firstItem: {
        date: moment().format("YYYY-MM-DD"),
        range: "08:00:00",
        time: "08:00:00",
      },
      items: [],
    };
  },
  computed: {
    ...mapGetters("workspace", ["allusers"]),
    okLabel() {
      return this.$can.and("manage_user_ptos")
        ? "Submit"
        : "Submit for Approval";
    },
    valid() {
      return !!(
        this.payload.items.length >= 1 &&
        this.payload.user_id &&
        this.payload.tz &&
        this.payload.description &&
        !this.hasInvalidEntry
      );
    },
    hasInvalidEntry() {
      return this.getInvalidEntries.length > 0;
    },
    getInvalidEntries() {
      return _.cloneDeep(this.all_items).filter((i) => !i.valid);
    },
    all_items() {
      return [this.firstItem, ...this.items];
    },
    payload() {
      return {
        user_id: this.selectedUser ? this.selectedUser.id : null,
        tz: this.selectedTz,
        description: this.description,
        items: _.cloneDeep(this.all_items).map((item, index) => {
          item.order = index;
          return item;
        }),
      };
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val;
      },
      immediate: true,
    },
    open: {
      handler: function (val) {
        this.$emit("input", val);
        if (!val) {
          this.resetInput();
        }
      },
      immediate: true,
      deep: true,
    },
    user: {
      handler: function (val) {
        if (typeof val === "string") {
          this.selectedUser = this.allusers.find((i) => i.id == val);
        } else this.selectedUser = val;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (!this.allusers.length) {
      let currentUser = this.$store.getters["user"];
      this.fetchWorkspaceAllUsers(currentUser.team_id);
    }
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceAllUsers"]),
    submit(reset) {
      this.submiting = true;
      this.$axios.get("sanctum/csrf-cookie").then(() => {
        this.$axios
          .post(`api/team-reports/add-ptos`, this.payload)
          .then(({ data }) => {
            this.appToast(data.message, "success");
            this.$event.$emit("refetch-ptos", true);
            this.open = false;
          })
          .catch((err) => reset())
          .finally(() => (this.submiting = false));
      });
    },
    resetInput() {
      this.items = [];
      this.submiting = false;
      this.description = null;
    },
    insert() {
      this.items.push({
        date: moment()
          .add(this.items.length + 1, "day")
          .format("YYYY-MM-DD"),
        range: "08:00:00",
        time: "08:00:00",
      });
    },
    duplicate(item) {
      let itemNew = _.cloneDeep(item);
      itemNew.date = moment()
        .add(this.items.length + 1, "day")
        .format("YYYY-MM-DD");
      this.items.push(itemNew);
    },
  },
  components: { UserSelect, TimezonePicker, CommandPicker, TimeRangeInput },
};
</script>

<style lang="scss" scoped></style>
