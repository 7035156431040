<template>
  <app-modal
    :has-footer="false"
    :max-width="maxWidth"
    title="SIGN IN WITH SLACK"
    v-model="open"
    :fullscreen="smDown"
  >
    <div
      class="xflex xmax-w-[500px] xflex-col text-center mx-auto xjustify-around xpy-[2em] xmin-h-full xgap-y-[1.5em]"
    >
      <p class="xtext-[16px] xfont-[400] xtext-[#616161] xleading-[160%]">
        Sign in with your Slack account. Mesasix Time Tracker will authenticate
        user from their Slack account using Slack OAuth.
      </p>
      <v-btn
        color="#4a154b"
        x-large
        height="80"
        :disabled="submitting"
        class="text-none"
        :href="slackButtonUrl"
        :style="btnSlackStyle"
      >
        <v-avatar tile size="30" class="mr-3">
          <v-img contain :src="slack_logo"></v-img>
        </v-avatar>
        Sign in with Slack
      </v-btn>

      <v-divider class="xmy-[1em]" />

      <p class="xtext-[16px] xfont-[400] xtext-[#616161] xleading-[160%]">
        Haven't add {{ appName }} to your Slack workspace yet?
      </p>
      <div
        @click="navigateRegister"
        class="xw-full hover:xbg-[#6a2c91] hover:xtext-[#ffffff] xbg-[#fff] xcursor-pointer xborder xborder-solid xborder-[#4a154b] xp-[1.5em] xtext-[#010101] xrounded-[4px] xtext-[19px] xfont-[500]"
      >
        Add {{ appName }} to Slack workspace
      </div>
    </div>
  </app-modal>
</template>

<script>
import httpBuildQuery from "http-build-query";
import { mapGetters } from "vuex";

export default {
  inheritAttrs: false,
  props: {
    value: { type: Boolean, default: false },
    maxWidth: { type: [String, Number], default: 700 },
    title: { type: String, default: "" },
  },
  data() {
    return {
      btnSlackStyle:
        "font-family: Lato, sans-serif; color: #fff; font-weight: 500; text-decoration: none;",
      slack_logo: require("@/assets/slack/slack-logo.svg"),
      open: false,
      submitting: false,
      email: null,
      password: null,
      showPassword: false,
      scope: "openid,email,profile",
    };
  },
  computed: {
    ...mapGetters("appconfigs", ["slack_client_id"]),
    slackButtonUrl() {
      return `https://slack.com/openid/connect/authorize?${httpBuildQuery({
        scope: this.scope,
        client_id: this.slackApp.client_id,
        redirect_uri: window.location.origin,
        response_type: "code",
      })}`;
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val;
      },
      immediate: true,
    },
    open: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },
  methods: {
    navigateRegister() {
      this.$router.push({ name: "install-app" }).catch(() => {});
      this.open = false;
    },
  },
};
</script>

<style scoped></style>
