<template>
  <div
    v-bind="$attrs"
    class="metric-box xflex xflex-col sm:!xmin-h-[180px] !xgap-[2em]"
    :style="{ backgroundColor: color }"
  >
    <h2>{{ title }}</h2>
    <span class="span-value">{{ value }}</span>
    <small>as of {{ since }}</small>
  </div>
</template>

<script>
import moment from "moment";
export default {
  inheritAttrs: false,
  props: {
    title: { type: String, default: "Title" },
    value: { type: String, default: "0hr 0min" },
    color: { type: String, default: "#fff" },
    since: { type: String, default: () => moment().format("LL") },
  },
};
</script>

<style lang="scss" scoped>
.metric-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em;
  min-height: 265px;
  border-radius: 10px;
  position: relative;

  h2 {
    width: 180px;
    height: 48px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  span {
    position: absolute;
    bottom: 0; 
    margin-bottom: 50px;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    font-size: 34px;
  }
  small {
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
}
</style>
