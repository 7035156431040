<template>
  <div
    :class="[laptopUp ? 'xgrid-cols-3' : 'xgrid-cols-1']"
    class="xgrid xw-full xpy-2 xitems-center"
  >
    <div class="xcol-span-2 xp-2"> 
      <slot name="title">
        <h1 class="xfont-[600] xtext-[#2e2e2e] xtext-[16px] xleading-[24px]">
          {{ title }}
        </h1>
      </slot>
      <slot name="subtitle">
        <p
          class="xtext-[#2e2e2e] xfont-[400] xtext-[14px] xleading-[24px]"
          v-html="subtitle"
        ></p>
      </slot>
      <v-btn
        color="#353535"
        outlined
        @click="toggle = !toggle"
        small
        dark
        depressed
        class="text-none my-1"
        v-if="withExpand"
      >
        Details
      </v-btn>
      <v-expand-transition v-if="withExpand">
        <div class="xflex xflex-col" v-if="toggle">
          <slot name="left"></slot>
        </div>
      </v-expand-transition>
    </div>
    <slot name="allright">
      <div class="xcol-span-1 xp-2">
        <slot name="right"></slot>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    title: { type: String, default: "" },
    subtitle: { type: String, default: "" },
    expand: { type: Boolean, default: false },
    withExpand: { type: Boolean, default: true },
  },
  watch: {
    expand: {
      handler: function (val) {
        this.toggle = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      toggle: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
