<template>
  <v-menu bottom left offset-y v-if="isEditable">
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip :color="block.type === 'on-work' ? `primary` : `warning`" top>
        <template v-slot:activator="{ on: tooltip }">
          <div
            @mouseenter="hover = true"
            @mouseleave="hover = false"
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
            :class="[
              block.type,
              hover ? 'hover' : '',
              hasEditBlock && isEditable ? 'xcursor-pointer' : '',
            ]"
            class="xh-[30px]"
            :style="{ width: `${block.percent}%` }"
          ></div>
        </template>
        <div class="xflex xflex-col xjustify-start xitems-start xgap-y-1">
          <div
            class="xflex xflex-row xjustify-start xtext-left xitems-start xgap-x-2"
          >
            <v-icon color="white" left>mdi-clock</v-icon>
            <span>{{ block.seconds | secToHuman(" Hours", " Minutes") }} </span>
          </div>
          <div
            class="xflex xflex-row xjustify-start xtext-left xitems-start xgap-x-2"
            v-if="block.type === 'on-work'"
          >
            <v-icon color="white" left>mdi-account-hard-hat</v-icon>
            <span>{{ block.project_name || "General Task" }} </span>
          </div>
          <div
            class="xflex xflex-row xjustify-start xtext-left xitems-start xgap-x-2"
            v-if="isEdited"
          >
            <v-icon color="white" left>mdi-account-edit-outline</v-icon>
            <span v-if="isApproved">Edit Approved</span>
            <span v-else>Edit Is Pending</span>
          </div>
        </div>
      </v-tooltip>
    </template>
    <div class="xbg-[#fff] xp-[0.5em]" v-if="hasEditBlock && isEditable">
      <v-btn
        @click="$emit('edit-block', block)"
        block
        tile
        color="primary"
        small
      >
        <v-icon left>mdi-pencil</v-icon> Edit
      </v-btn>
    </div>
  </v-menu>
  <div
    v-else
    class="xh-[30px] on-out"
    :style="{ width: `${block.percent}%` }"
  ></div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    block: { type: Object, required: true },
    hasEditBlock: { type: Boolean, default: true },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    isEditable() {
      return ["on-work", "on-break"].includes(this.block.type);
    },
    isEdited() {
      return this.isEditable && this.block.log_to.old_created_at ? true : false;
    },
    isApproved() {
      return this.isEditable && this.block.log_to.status === "approved"
        ? true
        : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.on-break {
  background: #fcaa3f;
  &.hover {
    background: #a59864 !important;
  }
}
.on-out {
  background: #efecec;
  &.hover {
    background: #797676 !important;
  }
}
.on-work {
  background: #44d941;
  &.hover {
    background: #40b53e !important;
  }
}
</style>
