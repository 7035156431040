<template>
  <v-card
    flat
    v-bind="$attrs"
    class="xflex xflex-col xjustify-center xitems-center xmin-h-[100px]"
  >
    <div class="xflex xflex-col xjustify-center xitems-center">
      <v-avatar height="80" size="75" class="xmb-1">
        <v-img contain :src="logoSquare" />
      </v-avatar>
      <span
        class="xfont-semibold xtext-center xtext-[20px] primary--text"
        v-html="text"
      ></span>
      <slot name="default"></slot>
    </div>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    text: { type: String, default: "No data yet" },
    icon: { type: String, default: "mdi-alert-box-outline" },
  },
};
</script>

<style lang="scss" scoped></style>
