<template>
  <v-container
    class="xmin-h-screen xmx-auto xflex xflex-col xitems-center xjustify-stretch"
    :class="{ '!xbg-none': smDown }"
    id="install"
    :style="{
      background: `url(${topLeft}) 100px top no-repeat, url(${rightBottom}) right bottom no-repeat, url(${leftBottom}) 100px bottom no-repeat`,
      backgroundColor: '#F3F3F3',
    }"
  >
    <div
      class="xflex xflex-col xw-full xpy-[2em] xpx-[2em] xjustify-center xitems-center"
    >
      <h2 class="install-label">
        GETTING STARTED WITH {{ appName | uppercase }}
      </h2>
      <p class="install-description md:xw-8/12 xmx-auto xmy-2">
        These are the important steps you should follow after installing
        {{ appName }}
      </p>
    </div>
    <v-card color="transparent" flat max-width="1366">
      <Collapse
        expanded
        class="xmb-1 xw-full"
        title="1. (Required) Select team members that will be using the app. Each selected member will be billed $2 per month."
      >
        <template v-slot:default>
          <div class="xw-fit xoverflow-hidden xflex xflex-col">
            <p class="xtext-[14px] xtext-[#e46464]" v-if="!canHandleStep">
              The following steps can be done by your Slack workspace Owner and
              Admins.
            </p>
            <div class="xflex xjustify-start xitems-start xflex-col">
              <v-list dense tag="ol">
                <v-list-item> a. Click “Billing” on the sidebar </v-list-item>
                <v-list-item>
                  b. In the table, toggle the switch icon for every team member
                  that will be using the app.
                </v-list-item>
                <v-list-item>
                  c. Click “Save Changes” when you’re done
                </v-list-item>
              </v-list>
              <img class="xml-[2em] xmax-h-[400px]" contain :src="payingImg" />
            </div>
          </div>
        </template>
      </Collapse>

      <Collapse
        expanded
        class="xmb-1 xw-full"
        title="2. (Optional) Go to “Settings” and customize your preferences."
      >
        <template v-slot:default>
          <div class="xw-fit xoverflow-hidden xflex xflex-col">
            <p class="xtext-[14px] xtext-[#e46464]" v-if="!canHandleStep">
              The following steps can be done by your Slack workspace Owner and
              Admins.
            </p>
            <div class="xflex xjustify-start xitems-start xflex-col">
              <v-list dense tag="ol">
                <v-list-item> a. On the sidebar, click “Settings” </v-list-item>
                <v-list-item>
                  b. The first tab is your “Preferences”, you can customize it
                  as you want.
                </v-list-item>
                <v-list-item>
                  c. You can also customize your “Bot Responses” on the second
                  tab, and so on ...
                </v-list-item>
                <v-list-item>
                  d. Click the “Save Changes” or “Update” button on the selected
                  tab if you made any changes
                </v-list-item>
              </v-list>
              <img
                class="xml-[2em] xmax-h-[400px]"
                contain
                :src="preferenceImg"
              />
            </div>
          </div>
        </template>
      </Collapse>

      <Collapse
        expanded
        class="xmb-1 xw-full"
        title="3. (Required) Notify your team members to use our Slack Commands! You can select a dedicated channel for them or they can just clock in privately in their direct messages."
      >
        <template v-slot:default>
          <div class="xw-fit xoverflow-hidden xflex xflex-col">
            <div class="xflex xjustify-start xitems-start xflex-col">
              <p class="xtext-[#000000]">
                They can use `/in`, `/brb`, `/back`, `/out`, and other
                commands.<br /><br />
                To view the list of our commands, you can follow these steps:
              </p>
              <v-list dense tag="ol">
                <v-list-item>
                  <div class="xflex xflex-col">
                    <span>
                      1. Type `/mesasix` on any channel, then click “Enter” or
                      Click the
                      <v-icon class="xinline" color="#007a5a">mdi-send</v-icon>
                      button
                    </span>
                    <img
                      class="xml-[2em] xmax-h-[200px]"
                      contain
                      :src="mesasixCmd"
                    />
                  </div>
                </v-list-item>
                <v-list-item>
                  <div class="xflex xflex-col">
                    2. Click “Choose” button on "Show Full Command List".
                    <img
                      class="xml-[2em] xmax-h-[300px]"
                      contain
                      :src="mesasixMenu"
                    />
                  </div>
                </v-list-item>
                <v-list-item>
                  <div class="xflex xflex-col">
                    3. A list of commands will be displayed
                    <img
                      class="xml-[2em] xmax-h-[420px]"
                      contain
                      :src="mesasixList"
                    />
                  </div>
                </v-list-item>
              </v-list>
            </div>
          </div>
        </template>
      </Collapse>

      <Collapse
        expanded
        class="xmb-1 xw-full"
        title="4. (Required) Login to the dashboard to check your team’s status!"
      >
        <template v-slot:default>
          <div
            class="xoverflow-hidden xflex xflex-col xjustify-center xitems-center xw-full"
          >
            <v-btn
              @click="goToDashboard"
              large
              outlined
              color="primary"
              class="text-none"
            >
              Go to Dashboard
            </v-btn>
          </div>
        </template>
      </Collapse>
    </v-card>


  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"; 
import Collapse from "./components/Collapse.vue";
export default {
  data() {
    return {
      registerOAuth: require("@/assets/slack/slack-register-oauth.png"),
      preferenceImg: require("@/assets/images/preferences.png"),
      payingImg: require("@/assets/images/add-to-paying.png"),
      topLeft: require("@/assets/covers/feature-t.svg"),
      leftBottom: require("@/assets/covers/feature-lb.svg"),
      rightBottom: require("@/assets/covers/feature-rb.svg"),
      mesasixList: require("@/assets/images/command-list.png"),
      mesasixMenu: require("@/assets/images/mesasix-menu.png"),
      mesasixCmd: require("@/assets/images/mesasix-cmd.png"),
    };
  },
  computed: {
    ...mapGetters(["user"]),
    canHandleStep() {
      return (
        this.user &&
        this.$can.or(
          "update_team_preferences|manage_member_subscription|update_subscription"
        )
      );
    },
  }, 
  methods: {
    ...mapMutations(["set_newInstall"]),
    goToDashboard() {
      if (this.$auth.isAuthenticated()) {
        this.$router.push({
          name: this.canHandleStep ? "app-subscription" : "app-dashboard",
        });
      } else {
        this.$router.push({
          name: "home",
          query: { login: true, redirect_uri: "/dashboard" },
        });
      }
    },
  },
  components: { Collapse },
};
</script>

<style lang="scss" scoped>
#install {
  .install-label {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.43em;
    color: #a240e3;
  }
  .install-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: #616161;
  }
  ol {
    padding: 0 !important;

    li {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
  }
}
</style>
