<template>
  <span
    class="xflex xflex-row xjustify-start xitems-center xgap-x-[3px]"
    v-bind="$attrs"
  >
    <span>{{ text }}</span>
    <v-icon
      size="15"
      :color="copied ? 'primary' : 'grey'"
      @click.stop="doCopy"
      right
    >
      mdi-content-copy
    </v-icon>
  </span>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    text: String,
  },
  data() {
    return { copied: false };
  },
  created() {
    this.$event.$on("clipboard-changed", (text) => {
      this.copied = text === this.text;
    });
  },
  methods: {
    doCopy: function () {
      this.$copyText(this.text).then(
        (e) => {
          this.$event.$emit("clipboard-changed", this.text);
          this.appToast("Success! Copied.", "success");
        },
        (e) => {
          this.appToast(
            "Cannot copy! Copy from clipboard are not supported on this browser.",
            "error"
          );
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
