<template>
  <v-card
    flat
    color="transparent"
    min-height="100vh"
    class="xw-full"
    :class="[tabletDown ? '' : 'xp-[1em]']"
  >
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">Member Report</h1>
    </v-card-title>
    <v-card flat class="!xw-full">
      <v-card-title class="xflex xjustify-start xgap-[0.5em] xitems-center">
        <UserSelect
          v-model="selectedUser"
          :class="mobile ? '!xw-full' : `xmax-w-[200px]`"
          return-object
          has-all
        ></UserSelect>
        <KirbyRangePicker
          no-future
          :class="
            mobile
              ? '!xw-full'
              : laptopUp
              ? `xmax-w-[350px]`
              : `xmax-w-[300px]`
          "
          v-model="selectedRange"
        ></KirbyRangePicker>
        <v-spacer></v-spacer>
        <btn-tooltip
          tip="Manually add time log for this user"
          color="primary"
          @click="add_time_modal = true"
          v-if="laptopUp && $can.and('manage_user_time')"
          class="text-none"
        >
          <v-icon left> mdi-calendar-plus </v-icon> Add Time
        </btn-tooltip>

        <btn-tooltip
          tip="Manually add PTO for this user"
          color="primary"
          @click="add_pto_modal = true"
          v-if="
            laptopUp &&
            $can.and('manage_user_ptos') &&
            isModuleEnabled('ptos')
          "
          class="text-none"
        >
          <v-icon left> mdi-calendar-plus </v-icon> Add PTO
        </btn-tooltip> 
        <btn-list
          v-if="laptopDown"
          icon="mdi-chevron-down"
          btn-color="primary"
          text-color="primary"
          outlined
        >
          <template v-slot:label>
            <v-icon small color="primary">mdi-form-dropdown</v-icon>
          </template>
          <v-list>
            <v-list-item
              v-if="$can.and('manage_user_ptos')"
              @click="add_pto_modal = true"
            >
              <v-list-item-title>Add PTO</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$can.and('manage_user_time')"
              @click="add_time_modal = true"
            >
              <v-list-item-title>Add Time</v-list-item-title>
            </v-list-item>
          </v-list>
        </btn-list>
        <btn-export
          v-if="$can.and('download_record')"
          :api-csv="csvUrl"
          :api-pdf="pdfUrl"
          :api-jpg="jpgUrl"
          :filename="fileName"
        ></btn-export>
      </v-card-title>
      <v-card-text>
        <app-table
          :loading="fetching"
          :headings="headers"
          :items="reports"
          empty-text="No data found for given range"
        >
          <template v-slot:real_name="{ item }">
            <avatar size="25" with-name :user="item"> </avatar>
          </template>
          <template v-slot:status="{ item }">
            <chip-in label v-if="item.status.on_work"></chip-in>
            <chip-brb label v-else-if="item.status.on_break"> </chip-brb>
            <chip-out label v-else-if="item.status.on_out"></chip-out>
          </template>
          <template v-slot:total_works="{ item }">
            <span>
              {{ item.total_works | secToHuman(" hours", " minutes") }}
            </span>
          </template>
          <template v-slot:actions="{ item }">
            <icon-list icon="mdi-dots-horizontal">
              <v-list dense>
                <v-list-item @click="viewDetails(item)">
                  <v-list-item-title class="xtext-[#2E2E2E]">
                    <v-icon color="#2E2E2E" left>
                      mdi-eye-circle-outline
                    </v-icon>
                    View Log Details
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="addPTO(item)"
                  v-if="$can.and('manage_user_ptos')"
                >
                  <v-list-item-title class="xtext-[#2E2E2E]">
                    <v-icon color="#2E2E2E" left>
                      mdi-plus-circle-outline
                    </v-icon>
                    Add PTO
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="addTime(item)"
                  v-if="$can.and('manage_user_time')"
                >
                  <v-list-item-title class="xtext-[#2E2E2E]">
                    <v-icon color="#2E2E2E" left>
                      mdi-plus-circle-outline
                    </v-icon>
                    Add Time
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </icon-list>
          </template>
        </app-table>
      </v-card-text>
    </v-card>

    <AddTimeModal v-model="add_time_modal" :user="selectedUser"></AddTimeModal>

    <AddPTOModal v-model="add_pto_modal" :user="selectedUser"></AddPTOModal>

    <!-- <pre>{{ reports }}</pre> -->
  </v-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import KirbyRangePicker from "@/views/GlobalComponents/Forms/KirbyRangePicker.vue";
import UserSelect from "@/views/GlobalComponents/Forms/UserSelect.vue";
import AddPTOModal from "@/views/GlobalComponents/Modals/AddPTOModal.vue";
import AddTimeModal from "@/views/GlobalComponents/Modals/AddTimeModal.vue";

export default {
  components: {
    KirbyRangePicker,
    UserSelect,
    AddPTOModal,
    AddTimeModal,
  },
  data() {
    return {
      add_time_modal: false,
      add_pto_modal: false,
      fetching: false,
      reports: [],
      initialLoad: true, // Flag to track initial load
      selectedUser: {
        id: "all",
        image_url: null,
        title: null,
        real_name: "All Members",
        role: { normalize_name: null },
      },
      allUser: {
        id: "all",
        image_url: null,
        title: null,
        real_name: "All Members",
        role: { normalize_name: null },
      },
      selectedRange: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      headers: [
        {
          text: "Member Name",
          value: "real_name",
          sortable: true,
        },
        {
          text: "Email Address",
          value: "email",
          sortable: true,
        },
        {
          text: "Type",
          value: "type",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          sortable: true,
        },
        {
          text: "Work Time",
          value: "total_works",
          sortable: true,
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", ["allusers"]),
    fileName() {
      return `${this.selectedUser.real_name}-${this.selectedRange[0]}-${this.selectedRange[1]}`;
    },
    csvUrl() {
      return `api/member-reports/${this.selectedUser.id}/${this.selectedRange[0]}/${this.selectedRange[1]}/csv`;
    },
    pdfUrl() {
      return `api/member-reports/${this.selectedUser.id}/${this.selectedRange[0]}/${this.selectedRange[1]}/pdf`;
    },
    jpgUrl() {
      return `api/member-reports/${this.selectedUser.id}/${this.selectedRange[0]}/${this.selectedRange[1]}/jpg`;
    },
    from() {
      return this.selectedRange
        ? moment(this.selectedRange[0])
        : moment().subtract(1, "day");
    },
    to() {
      return this.selectedRange ? moment(this.selectedRange[1]) : moment();
    },
  },
  watch: {
    selectedUser: 'fetchReportIfNeeded',
    selectedRange: 'debouncedFetchReportIfNeeded',
  },
  mounted() {
    this.fetchReportIfNeeded(true); // Ensure the fetch is called on initial load
  },
  methods: {
    fetchReportIfNeeded(initial = false) {
      if (this.selectedUser && this.selectedRange && this.selectedRange.length === 2) {
        if (initial) {
          this.initialLoad = false;
          this.fetchReport();
        } else if (!this.initialLoad) {
          this.fetchReport();
        }
      }
    },
    debouncedFetchReportIfNeeded: _.debounce(function() {
      this.fetchReportIfNeeded();
    }, 500), // Adjust the debounce delay as needed
    async fetchReport() {
      await new Promise((resolve) => {
        this.$nextTick(resolve);
      });

      if (!this.selectedUser) return;

      this.fetching = true;
      this.$axios
        .get(
          `api/member-reports/${this.selectedUser.id}/${this.selectedRange[0]}/${this.selectedRange[1]}`
        )
        .then(({ data }) => {
          this.reports = data;
        })
        .finally(() => (this.fetching = false));
    },
    addPTO(item) {
      this.selectedUser = item;
      this.add_time_modal = false;
      this.add_pto_modal = true;
    },
    addTime(item) {
      this.selectedUser = item;
      this.add_time_modal = true;
      this.add_pto_modal = false;
    },
    viewDetails(item) {
      this.$router.push({
        name: "app-user-record-custom",
        params: {
          id: item.id,
          start: this.from.format("YYYY-MM-DD"),
          end: this.to.format("YYYY-MM-DD"),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>