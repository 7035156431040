<template>
  <app-modal
    :key="open"
    :max-width="800"
    title="Edit Block Time"
    v-model="open"
    :valid="valid"
    :confirm-label="$can.and('manage_user_time') ? 'Submit' : 'Submit'"
    @cancelled="$emit('reset-block')"
    @confirmed="submit"
  >
    <div
      class="xflex xflex-col xmax-w-[750px] my-5 text-center mx-auto xjustify-center xmin-h-full xgap-y-4"
    >
      <app-input-group
        label="Range Time Duration"
        text-align="center"
        class="xw-full xtext-center xmx-auto"
      >
        <template v-slot:right>
          <div
            class="xborder xborder-solid xtext-[#2e2e2e] xtext-[17px] xfont-[400] xborder-[#d1d1d1] xrounded-[5px] xp-[10px]"
          >
            {{ durations }}
          </div>
        </template>
      </app-input-group>

      <app-input-group
        label="Project/Task"
        text-align="center"
        v-if="canHaveProject"
        class="xw-full xtext-center xmx-auto"
      >
        <template v-slot:right>
          <ProjectSelect
            :has-create="$can.and('manage_projects')"
            return-name
            v-model="project"
          ></ProjectSelect>
        </template>
      </app-input-group>

      <app-input-group class="xw-full xtext-center xmx-auto">
        <template v-slot:left>
          <v-row no-gutters justify="space-between" align="center">
            <v-col class="xfont-semibold xtext-[#2e2e2e] xtext-[15px]">
              From
            </v-col>
            <v-col>
              <v-chip
                class="xw-full xflex xjustify-center !xtext-[#000] xuppercase"
                :color="getColor(logFrom.command)"
              >
                {{ logFrom ? logFrom.command : "" | ucwords }}
              </v-chip>
            </v-col>
          </v-row>
        </template>
        <template v-slot:right>
          <kirby-datetime-picker
            has-buttons
            :min="lowerBoundary"
            :max="to"
            v-model="from"
          >
          </kirby-datetime-picker>
        </template>
      </app-input-group>
      <div class="xflex xp-[5px] xflex-row xjustify-end xgap-[1em]">
        <div>“From” can be adjusted within range :</div>
        <div
          class="xflex xflex-row xjustify-around xgap-2 xflex-nowrap xitems-center"
        >
          <div class="xfont-semibold">{{ lowerBoundary | format("lll") }}</div>
          <v-icon size="15">mdi-clock-start</v-icon>
          <div class="xfont-semibold">{{ to | format("lll") }}</div>
        </div>
      </div>

      <app-input-group class="xw-full xtext-center xmx-auto">
        <template v-slot:left>
          <v-row no-gutters justify="space-between" align="center">
            <v-col class="xfont-semibold xtext-[#2e2e2e] xtext-[15px]">
              To
            </v-col>
            <v-col>
              <v-chip
                class="xw-full xflex xjustify-center !xtext-[#000] xuppercase"
                :color="getColor(logTo.command)"
              >
                {{ logTo ? logTo.command : "" | ucwords }}
              </v-chip>
            </v-col>
          </v-row>
        </template>
        <template v-slot:right>
          <kirby-datetime-picker
            has-buttons
            :min="from"
            :max="upperBoundary"
            v-model="to"
          >
          </kirby-datetime-picker>
        </template>
      </app-input-group>
      <div
        class="xflex xp-[5px] xflex-row xjustify-end xgap-[1em]"
        v-if="logTo && logTo.id"
      >
        <div>“To” can be adjusted within range :</div>
        <div
          class="xflex xflex-row xjustify-around xgap-2 xflex-nowrap xitems-center"
        >
          <div class="xfont-semibold">{{ from | format("lll") }}</div>
          <v-icon size="15">mdi-clock-start</v-icon>
          <div class="xfont-semibold">{{ upperBoundary | format("lll") }}</div>
        </div>
      </div>
      <div
        v-else
        class="xflex xflex-row xjustify-end xtext-right xitems-end xfont-semibold xtext-red-400 xw-full"
      >
        “To” cannot be adjusted as it still ongoing
        <v-icon color="red" right size="15">mdi-clock-end</v-icon>
      </div>

      <app-input-group
        v-if="!$can.and('manage_user_time')"
        class="xw-full xtext-center xmx-auto"
        label="Send to admins for approval?"
      >
        <template v-slot:right>
          <v-switch
            class="xmx-[1em] xw-fit"
            v-model="auto_send"
            inset
            color="success"
          ></v-switch>
        </template>
      </app-input-group>
      <!-- <div>
        User with manage user time permission are automatically approved.
      </div> -->
      <!-- <div class="xgrid xgrid-cols-1 w-full text-left">
        <pre class="xcol-span-1 xmb-1">payload: {{ payload }} </pre>
      </div> -->
    </div>
  </app-modal>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import ProjectSelect from "../Forms/ProjectSelect.vue";

export default {
  inheritAttrs: false,
  components: { ProjectSelect },
  props: {
    value: { type: Boolean, default: false },
    block: { type: Object, default: undefined },
  },
  data() {
    return {
      auto_send: true,
      open: false,
      from: moment().format("YYYY-MM-DD HH:mm:ss"),
      to: moment().format("YYYY-MM-DD HH:mm:ss"),
      project: null,
      logFrom: null,
      logTo: null,
      lower: null,
      upper: null,
    };
  },
  computed: {
    payload() {
      return {
        start_tid: this.logFrom ? this.logFrom.id : null,
        start_new_created_at: this.from
          ? moment(this.from).format("YYYY-MM-DD HH:mm:ss")
          : null,
        end_tid: this.logTo ? this.logTo.id : null,
        end_new_created_at: this.to
          ? moment(this.to).format("YYYY-MM-DD HH:mm:ss")
          : null,
        project_name: this.project,
        timezone: moment.tz.guess(),
        auto_send: this.auto_send,
      };
    },
    upperBoundary() {
      return this.upper
        ? moment(this.upper.created_at).format("YYYY-MM-DD HH:mm:ss")
        : moment().format("YYYY-MM-DD HH:mm:ss");
    },
    lowerBoundary() {
      return this.lower
        ? moment(this.lower.created_at).format("YYYY-MM-DD HH:mm:ss")
        : moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss");
    },
    durations() {
      if (
        !this.payload ||
        !this.payload.start_new_created_at ||
        !this.payload.end_new_created_at
      )
        return "0 hours 0 minutes ";
      return this.durationFormat(this.from, this.to, "H [hours] m [minutes]");
    },
    canHaveProject() {
      return this.logFrom && !["brb", "out"].includes(this.logFrom.command);
    },
    valid() {
      return true;
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val;
      },
      deep: true,
      immediate: true,
    },
    open: {
      handler: function (val) {
        if (!val) {
          this.prefill(null);
          this.upper = null;
          this.lower = null;
        }
        this.$emit("input", val);
      },
      deep: true,
      immediate: true,
    },
    block: {
      handler: function (val) {
        this.prefill(_.cloneDeep(val));
        this.getBoundary(_.cloneDeep(val));
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    submit(ev) {
      this.$emit("setChanges", { payload: this.payload, reset: ev });
      this.$event.$emit("refetch-pending-edits", true);
    },
    getColor(val) {
      if (!val) return "secondary";
      else if (val == "in" || val == "back") return "success";
      else if (val == "switch") return "info";
      else if (val == "brb") return "warning";
      else return "error";
    },
    prefill(val) {
      this.project = val ? val.project_name : null;
      this.from =
        val && val.log_from
          ? moment(val.log_from.created_at).format("YYYY-MM-DD HH:mm:ss")
          : moment().format("YYYY-MM-DD HH:mm:ss");
      this.to =
        val && val.log_to
          ? moment(val.log_to.created_at).format("YYYY-MM-DD HH:mm:ss")
          : moment().format("YYYY-MM-DD HH:mm:ss");
      this.logFrom = val ? val.log_from : null;
      this.logTo = val ? val.log_to : null;
    },
    getBoundary(block) {
      if (block && block.log_from && block.log_to) {
        this.$axios
          .get(
            `api/records/boundary/${block.log_from.id || null}/${
              block.log_to.id || null
            }`
          )
          .then(({ data }) => {
            this.upper = data.upper;
            this.lower = data.lower;
          });
      } else {
        this.upper = null;
        this.lower = null;
      }
    },
  },
};
</script>

<style lang="css"></style>
