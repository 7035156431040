<template>
  <div
    class="xbg-[#ffffff] xw-full xflex xflex-col xjustify-center xitems-start features-block md:xh-[80vh] xh-full xmin-h-[620px] xoverflow-y-hidden xoverflow-x-hidden"
  >
    <div
      :class="{ '!xmax-w-[90%]': tablet }"
      class="xcontainer xmx-auto xh-full xgrid md:xgrid-cols-2 xgrid-cols-1 xgap-x-[1em] xjustify-start xitems-center"
    >
      <div
        class="xcol-span-1 x-full xflex xflex-col xjustify-center xitems-center"
      >
        <v-carousel
          v-model="featureSlide"
          color="primary"
          :show-arrows="laptopDown"
          :hide-delimiters="laptopDown"
          :height="tabletDown ? `auto` : laptopUp ? 420 : `auto`"
          transition="slide-x-transition"
          hide-delimiter-background
          delimiter-icon="mdi-minus-thick"
        >
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <div
              class="xflex xflex-col lg:xp-[2em] xp-[1em] xjustify-center xitems-stretch xorder-2"
            >
              <h2
                class="xtext-[#692D91] xfont-[900] xmb-[1em] xtext-[13px] xuppercase xtracking-[0.43em] xleading-[20px]"
              >
                Features
              </h2>
              <div
                class="xfont-[700] xmb-[1em] xtext-[42px] xleading-[51px] xtext-[#000]"
              >
                {{ slide.title }}
              </div>
              <p class="xtext-[18px] xleading-[29px] xtext-[#616161]">
                {{ slide.description }}
              </p>
              <div
                v-if="!tabletUp"
                class="xcol-span-1 x-full xflex xflex-col xjustify-center xitems-center xorder-1"
              >
                <v-img
                  contain
                  class="xmin-h-[500px] xmin-w-[300px] md:xmax-w-[600px] xmax-w-full"
                  :src="slide.image"
                ></v-img>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
      <div
        v-if="tabletUp"
        class="xcol-span-1 x-full xflex xflex-col xjustify-center xitems-center xorder-1"
      >
        <v-img
          v-for="(slide, i) in slides"
          contain
          :key="i"
          :class="[featureSlide !== i ? 'xhidden' : '']"
          class="xmin-h-[620px] xmin-w-[300px] md:xmax-w-[600px] xmax-w-full"
          :src="slide.image"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureBlock",
  data() {
    return {
      homeFront2: require("@/assets/covers/homebg2.png"),
      featureSlide: 0,
      windowH: 0,
      slides: [
        {
          title: `Clock In and Out with Mesasix Time Tracker Bot`,
          description:
            "It has never been easier to track your team members' work time than with the Mesasix Time Tracker. This application tracks your team members' time from the start to the end of their shift through our Slack commands. Time reports are available on our dashboard!",
          image: require("@/assets/features/clock-in-out-full.svg"),
          imgSize: "40% 138%",
        },
        {
          title: "Check Your Total Work Time",
          description:
            "This feature allows you to view your total work time for any given day. Simply select the month, and view all of your hours worked for each day in that month.",
          image: require("@/assets/features/check-total-time-full.svg"),
          imgSize: "40% 132%",
        },
        {
          title: "Team Members Time Report",
          description:
            "This feature is for work time evaluation. It displays the team member's work time totaled by day, week, and month.",
          image: require("@/assets/features/team-member-report-full.svg"),
          imgSize: "40% 137%",
        },
        {
          title: "Customized Bot Responses",
          description:
            "Be more personal by customizing the bot responses to your team member’s Slack commands.",
          image: require("@/assets/features/customized-bots-full.svg"),
          imgSize: "35% 144%",
        },
        {
          title: "Clock Status Monitoring & Notifications",
          description:
            "No need to constantly monitor or check if your team members are idle during work hours. Mesasix Time Tracker takes care of it with notifications based on your preferences.",
          image: require("@/assets/features/status-monitoring-full.svg"),
          imgSize: "40% 143%",
        },
        {
          title: "Settings & Preferences",
          description:
            "Customize your team’s time tracker to suit your company policies, rules and regulations.",
          image: require("@/assets/features/settings-full.svg"),
          imgSize: "40% 128%",
        },
      ],
    };
  },
  computed: {
    activeSlide() {
      return this.slides[this.featureSlide];
    },
  },
  mounted() {
    this.onResize();
  },

  methods: {
    onResize() {
      this.windowH = window.innerHeight;
    },
  },
};
</script>

<style lang="css">
.features-block {
  background-position: right 100px bottom -200px !important;
}

.features-block .feature-images {
  background-position-x: right 100px !important;
  background-position-y: bottom 0px !important;
}

.v-carousel__controls .v-item-group {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  width: 100%;
}
.v-carousel__controls .v-carousel__controls__item .v-icon {
  font-size: 70px !important;
  color: gray;
  border-radius: 20px;
}
.v-carousel__controls .v-carousel__controls__item.v-item--active .v-icon {
  color: #6a2c91;
}
</style>
