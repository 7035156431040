<template>
  <v-card
    flat
    color="transparent"
    min-height="100vh"
    :class="[tabletDown ? '' : 'xp-[1em]']"
  >
    <v-card-title class="xflex xflex-col xjustify-start xitems-start">
      <v-btn
        text
        depressed
        class="text-none xmb-[1em] xtext-[#9A9A9A] xtext-[14px] xleading-[17px]"
        @click.stop="$router.go(-1)"
      >
        <v-icon left>mdi-chevron-left</v-icon> Go back
      </v-btn>
      <h1 class="xfont-[600] xw-full xtext-[24px] xleading-[29px]">
        My Settings
      </h1>
    </v-card-title>
    <v-card flat>
      <v-card-text class="xmin-h-[65vh]">
        <v-form ref="form" v-model="clocking_settings_valid">
          <EachSetting
            expand
            title="Set Default Project/Task"
            subtitle="Set your default project or task."
            class="xgrid md:xgrid-cols-3 xgap-3 mb-2 xjustify-center xitems-center w-full"
          >
            <template v-slot:left>
              <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
                Set your default project or task. When set you can then omit
                project or task name during clock
                <chip-in small text="/in" label></chip-in>
              </div>
            </template>
            <template v-slot:right>
              <ProjectSelect
                :rules="[requiredRule()]"
                v-model="default_project"
                placeholder="Select project"
                :has-create="false"
                class="xmx-auto xmax-w-[300px]"
              ></ProjectSelect>
            </template>
          </EachSetting>
          <v-divider />

          <EachSetting
            expand
            title="Ask Me Every Clock In for Project/Task?"
            subtitle=""
            class="xgrid md:xgrid-cols-3 xgap-3 mb-2 xjustify-center xitems-center w-full"
          >
            <template v-slot:left>
              <strong>
                Yes. Ask me everytime i forgot to include my project/task
              </strong>
              <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
                Every time you clock
                <chip-in small text="/in" label></chip-in> you will need to
                include the name or short code of your project or task.<br />
                e.g <b class="xtext-red-600">/in my-project-1</b>
              </div>
              <strong> No. Don`t ask me. Just use default project/task </strong>
              <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
                Adding a project or task name will only be optional. If not
                provided, the system will use what you have set in
                <b>Set Default Project/Task</b>. In case you have not set a
                default project/task the system will use the default project of
                your team.
              </div>
            </template>
            <template v-slot:right>
              <div
                class="xflex xw-full xflex-col xjustify-center xitems-center"
              >
                <v-select
                  dense
                  v-model="allow_task_notification"
                  outlined
                  class="xmx-auto xw-full xmax-w-[300px]"
                  hide-details="auto"
                  :items="choices"
                ></v-select>
              </div>
            </template>
          </EachSetting>
          <v-divider />
        </v-form>
      </v-card-text>
      <v-card-actions
        class="xmy-[1em] xflex xflex-row xgap-[0.5em] xjustify-center xitems-center"
      >
        <v-btn
          @click.stop="$router.go(-1)"
          color="#9a9a9a"
          depressed
          dark
          class="text-none xmr-4 px-5"
        >
          Cancel
        </v-btn>
        <v-btn
          depressed
          :loading="submitting_clocking_settings"
          :disabled="!clocking_settings_valid || submitting_clocking_settings"
          @click="submitClockingSettings"
          color="primary"
          class="text-none xmr-4 px-5"
        >
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- <pre>{{ payload }}</pre> -->
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProjectModal from "@/views/GlobalComponents/Modals/ProjectModal.vue";
import EachSetting from "../Settings/components/EachSetting.vue";
import ProjectSelect from "@/views/GlobalComponents/Forms/ProjectSelect.vue";
export default {
  data() {
    return {
      allow_task_notification: 0,
      default_project: null,
      choices: [
        {
          text: "Yes. Ask me everytime i forgot to include my project/task",
          value: 1,
        },
        {
          text: "No. Don`t ask me. Just use default project/task",
          value: 0,
        },
      ],
      clocking_settings_valid: false,
      submitting_clocking_settings: false,
      projects: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspaceconfigs", ["allow_user_create_project_tasks"]),
    payload() {
      return {
        allow_task_notification: this.allow_task_notification,
        default_project: this.default_project,
      };
    },
    tabs() {
      return [
        {
          to: null,
          name: "My Settings",
        },
      ];
    },
  },
  created() {
    this.fetchUserSetting();
    this.fetchWorkspaceProjects(this.user.team_id);
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceProjects"]),
    fetchUserSetting() {
      this.$axios.get(`api/configs/user`).then(({ data }) => {
        this.allow_task_notification = data.allow_task_notification;
        this.default_project = data.default_project;
      });
    },
    submitClockingSettings() {
      this.submitting_clocking_settings = true;
      this.$axios
        .put(`api/configs/user`, this.payload)
        .then(({ data }) => {
          this.appToast("Successfully updated", "success");
          this.$auth.fetchUser(() => {
            this.$router.push({ name: "app-dashboard" });
          });
        })
        .finally(() => (this.submitting_clocking_settings = false));
    },
  },
  components: { ProjectModal, EachSetting, ProjectSelect },
};
</script>

<style lang="scss" scoped></style>
